import * as types from "constants/types";

class AdminsAction {
  getAllUsersRequest = (page, filterObject) => ({
    type: types.GET_ALL_USERS.REQUEST,
    page,
    filterObject,
  });
  getAllUserSucceeded = (allUserList, total) => ({
    type: types.GET_ALL_USERS.SUCCEEDED,
    allUserList,
    total,
  });
  getAllUserFailed = (error) => ({
    type: types.GET_ALL_USERS.FAILED,
    error,
  });

  getAllRequestPropertiesRequest = (page, filterObject) => ({
    type: types.GET_ALL_REQUEST_PROPERTIES.REQUEST,
    page,
    filterObject,
  });
  getAllRequestPropertiesSucceeded = (allRequestPropertyList, total) => ({
    type: types.GET_ALL_REQUEST_PROPERTIES.SUCCEEDED,
    allRequestPropertyList,
    total,
  });
  getAllRequestPropertiesFailed = (error) => ({
    type: types.GET_ALL_REQUEST_PROPERTIES.FAILED,
    error,
  });

  getAllPublishedPropertiesRequest = (page) => ({
    type: types.GET_ALL_PUBLISHED_PROPERTIES.REQUEST,
    page,
  });
  getAllPublishedPropertiesSucceeded = (allPublishedPropertyList, total) => ({
    type: types.GET_ALL_PUBLISHED_PROPERTIES.SUCCEEDED,
    allPublishedPropertyList,
    total,
  });
  getAllPublishedPropertiesFailed = (error) => ({
    type: types.GET_ALL_PUBLISHED_PROPERTIES.FAILED,
    error,
  });

  getAllPropertiesRequest = (page, filterObject) => ({
    type: types.GET_ALL_PROPERTIES.REQUEST,
    page,
    filterObject,
  });
  getAllPropertiesSucceeded = (allPropertyList, total, page) => ({
    type: types.GET_ALL_PROPERTIES.SUCCEEDED,
    allPropertyList,
    total,
    page
  });
  getAllPropertiesFailed = (error) => ({
    type: types.GET_ALL_PROPERTIES.FAILED,
    error,
  });

  updateUserStatus = (userId, status, cb) => ({
    type: types.UPDATE_USER_STATUS.ROOT,
    userId,
    status,
    cb,
  });

  updatePropertyStatus = (propertyId, status, page, cb) => ({
    type: types.UPDATE_PROPERTY_STATUS.ROOT,
    propertyId,
    status,
    page,
    cb,
  });

  uploadPropertyImagesLandingPage = (propertyImagesLandingPage, cb) => ({
    type: types.UPLOAD_PROPERTY_LANDING_PAGE.ROOT,
    propertyImagesLandingPage,
    cb,
  });

  getAdminDashboardInfoRequest = () => ({
    type: types.GET_ADMIN_DASHBOARD_INFO.REQUEST,
  });
  getAdminDashboardInfoSucceeded = (adminDashboardInfo) => ({
    type: types.GET_ADMIN_DASHBOARD_INFO.SUCCEEDED,
    adminDashboardInfo,
  });
  getAdminDashboardInfoFailed = (error) => ({
    type: types.GET_ADMIN_DASHBOARD_INFO.FAILED,
    error,
  });
}

export default new AdminsAction();
