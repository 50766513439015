import React, { isValidElement } from "react";
import {
  Flex,
  Stack,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";

import hamburgerIcon from "assets/icons/hamburger.svg";

import { NavbarContainer, HStack } from "./navbar.styles";
import MobileNavContent from "../MobileNavContent";
import Divider from "components/Divider";
import Icon from "components/Icon";

const Template = (props) => {
  const { mobileNav } = props;

  const [checkMobileView] = useMediaQuery("(max-width: 767px)");

  const children = React.Children.toArray(props.children).filter(
    isValidElement
  );

  return (
    <NavbarContainer borderBottomWidth={useColorModeValue("none", "1px")}>
      <Icon
        icon={hamburgerIcon}
        size={24}
        height={16}
        onClick={mobileNav.onOpen}
        style={{
          marginRight: "20px",
          display: checkMobileView ? "block" : "none",
        }}
      />
      {children.find((child) => child.type === Brand)?.props.children}
      <HStack
        style={{
          marginRight: "auto",
        }}>
        {children.find((child) => child.type === Links)?.props.children}
      </HStack>
      <HStack
        display={{
          base: "none",
          md: "flex",
        }}
        spacing={3}>
        {children.find((child) => child.type === AuthButton)?.props.children}
      </HStack>
      <HStack
        position="relative"
        display={{
          base: "none",
          md: "flex",
        }}
        spacing={3}>
        {children.find((child) => child.type === UserProfile)?.props.children}
      </HStack>
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose}>
        <Stack width="100%" spacing={5}>
          <Flex>
            {children.find((child) => child.type === Brand)?.props.children}
          </Flex>
          <Stack>
            {children.find((child) => child.type === Links)?.props.children}
          </Stack>
          <Divider />
          <Flex>
            {
              children.find((child) => child.type === AuthButton)?.props
                .children
            }
          </Flex>
          <Flex>
            {
              children.find((child) => child.type === UserProfile)?.props
                .children
            }
          </Flex>
        </Stack>
      </MobileNavContent>
    </NavbarContainer>
  );
};

const Brand = () => null;

const Links = () => null;

const UserProfile = () => null;

const AuthButton = () => null;

const Navbar = Object.assign(Template, {
  Brand,
  Links,
  UserProfile,
  AuthButton,
});

export default Navbar;
