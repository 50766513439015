import { Flex, Checkbox } from "@chakra-ui/react";
import React from "react";
import { Form, Formik } from "formik";

import AuthsAction from "stores/redux/actions/AuthsAction";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  formControlStyle,
  signUpValidation,
} from "components/AuthenticationPopup/constants";
import history from "utils/history";

import useNotification from "hooks/useNotification";

import googleIcon from "assets/icons/google.svg";
import facebookIcon from "assets/icons/facebook.svg";

import Icon from "components/Icon";
import {
  ButtonSocial,
  SignUpPopupContainer,
  DividerText,
  DividerContainer,
  SignUpPopUpPrivacyContainer,
  Text,
  SignUpButton,
  SignUpPopUpPrivacyText,
} from "./signUpPopup.styles";
import InputField from "components/InputField";
import TitleText from "components/TitleText";
import Divider from "components/Divider";
import NormalText from "components/NormalText";

const SignUpPopup = ({ setAuthMode, closeModal, redirectTo }) => {
  const loading = useSelector(
    (state) => state.authsReducer.loading,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { signUpRequest } = AuthsAction;

  const { openNotificationSuccess, openNotificationError } = useNotification();

  const handleSignUpError = (error) => openNotificationError(error);

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        privacyChecked: false,
      }}
      validationSchema={signUpValidation}
      onSubmit={(values, actions) => {
        const handleSignUpSucceeded = () => {
          if (redirectTo) history.push(redirectTo);

          openNotificationSuccess("You have successfully registered");
          closeModal();
          actions.resetForm();
        };

        dispatch(
          signUpRequest(values, handleSignUpSucceeded, handleSignUpError)
        );
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <TitleText
            position="absolute"
            top={18}
            title="Sign Up"
            width="max-content"
          />
          <SignUpPopupContainer>
            <ButtonSocial
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`)
              }
              leftIcon={<Icon icon={googleIcon} size={24} />}>
              Sign Up with Google
            </ButtonSocial>
            <ButtonSocial
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/auth/facebook`)
              }
              leftIcon={<Icon icon={facebookIcon} size={24} />}>
              Sign Up with Facebook
            </ButtonSocial>
            <DividerContainer>
              <Divider />
              <DividerText>Or</DividerText>
              <Divider />
            </DividerContainer>
            <InputField
              type="text"
              name="username"
              label="Your name"
              value={values.username}
              touched={touched.username}
              error={errors.username}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlStyle={formControlStyle}
            />
            <InputField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlStyle={formControlStyle}
            />
            <InputField
              type="password"
              name="password"
              label="Password"
              placeholder="At least 8 characters"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <SignUpPopUpPrivacyContainer>
              <Flex
                cursor="pointer"
                onClick={() =>
                  setFieldValue("privacyChecked", !values.privacyChecked)
                }>
                <Checkbox
                  isChecked={values.privacyChecked}
                  mr="8px"
                  h="20px"
                  onChange={(event) =>
                    setFieldValue("privacyChecked", !event.target.checked)
                  }
                />
                <SignUpPopUpPrivacyText>
                  By Signing Up, you agree to our{" "}
                  <SignUpPopUpPrivacyText privacyMode>
                    Terms of Service
                  </SignUpPopUpPrivacyText>{" "}
                  &{" "}
                  <SignUpPopUpPrivacyText privacyMode>
                    Privacy Policy
                  </SignUpPopUpPrivacyText>
                </SignUpPopUpPrivacyText>
              </Flex>
            </SignUpPopUpPrivacyContainer>
            <SignUpButton
              type="submit"
              buttonText="Sign Up"
              loading={loading}
              hoverBackgroundColor="background.primary"
              disabled={Object.keys(errors).length > 0}
            />
            <Flex justify="center">
              <NormalText
                text=" Already have an account?"
                bold
                fontSize="16px"
                marginRight="5px"
              />
              <Text onClick={() => setAuthMode("login")}>Sign In</Text>
            </Flex>
          </SignUpPopupContainer>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpPopup;
