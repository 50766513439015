import { Stack, StackDivider } from "@chakra-ui/react";
import React from "react";

import {
  FooterContainer,
  FooterWrapper,
  FooterRightSection,
} from "./footer.styles";
import LinkGrid from "./components/LinkGrid";
import SubscribeForm from "./components/SubscribeForm";
import CopyRight from "./components/CopyRight";

const Footer = () => (
  <>
    <FooterContainer as="footer" role="contentinfo">
      <Stack spacing="10" divider={<StackDivider />}>
        <FooterWrapper
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: "10", lg: "28" }}>
          <FooterRightSection>
            <LinkGrid />
            {/* <SubscribeForm /> */}
          </FooterRightSection>
        </FooterWrapper>
      </Stack>
    </FooterContainer>
    <CopyRight />
  </>
);

export default Footer;
