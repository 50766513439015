import accountIcon from "assets/icons/account.svg";
import propertyIcon from "assets/icons/property.svg";
// import activityIcon from "assets/icons/activity.svg";

export const accountMenuList = [
  {
    path: "/my-account",
    label: "My Account",
    icon: accountIcon,
  },
  {
    path: "/my-property",
    label: "My Property",
    icon: propertyIcon,
  },
  // {
  //   path: "/my-activity",
  //   label: "My Activity",
  //   icon: activityIcon,
  // },
];
