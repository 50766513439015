import produce from "immer";

import * as types from "constants/types";

const initialState = {
  allUserList: {
    loading: false,
    data: [],
    total: 0,
    error: "",
  },
  allPublishedPropertyList: {
    loading: false,
    data: [],
    total: 0,
    error: "",
  },
  allRequestPropertyList: {
    loading: false,
    data: [],
    total: 0,
    error: "",
  },
  allPropertyList: {
    loading: false,
    data: [],
    total: 0,
    page:1,
    error: "",
  },
  adminDashboardInfo: {
    loading: false,
    totalProperties: 0,
    totalPublishedProperties: 0,
    totalUsers: 0,
    error: "",
  },
};

const adminsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_ALL_USERS.REQUEST: {
        draft.allUserList.loading = true;
        break;
      }
      case types.GET_ALL_USERS.SUCCEEDED: {
        draft.allUserList.loading = false;
        draft.allUserList.data = action.allUserList;
        draft.allUserList.total = action.total;
        break;
      }
      case types.GET_ALL_USERS.FAILED: {
        draft.allUserList.loading = false;
        draft.allUserList.error = action.error;
        break;
      }

      case types.GET_ALL_REQUEST_PROPERTIES.REQUEST: {
        draft.allRequestPropertyList.loading = true;
        break;
      }
      case types.GET_ALL_REQUEST_PROPERTIES.SUCCEEDED: {
        draft.allRequestPropertyList.loading = false;
        draft.allRequestPropertyList.data = action.allRequestPropertyList;
        draft.allRequestPropertyList.total = action.total;
        break;
      }
      case types.GET_ALL_REQUEST_PROPERTIES.FAILED: {
        draft.allRequestPropertyList.loading = false;
        draft.allRequestPropertyList.error = action.error;
        break;
      }

      case types.GET_ALL_PUBLISHED_PROPERTIES.REQUEST: {
        draft.allPublishedPropertyList.loading = true;
        break;
      }
      case types.GET_ALL_PUBLISHED_PROPERTIES.SUCCEEDED: {
        draft.allPublishedPropertyList.loading = false;
        draft.allPublishedPropertyList.data = action.allPublishedPropertyList;
        draft.allPublishedPropertyList.total = action.total;
        break;
      }
      case types.GET_ALL_PUBLISHED_PROPERTIES.FAILED: {
        draft.allPublishedPropertyList.loading = false;
        draft.allPublishedPropertyList.error = action.error;
        break;
      }

      case types.GET_ALL_PROPERTIES.REQUEST: {
        draft.allPropertyList.loading = true;
        break;
      }
      case types.GET_ALL_PROPERTIES.SUCCEEDED: {
        draft.allPropertyList.loading = false;
        draft.allPropertyList.data = action.allPropertyList;
        draft.allPropertyList.total = action.total;
        draft.allPropertyList.page = action.page;
        break;
      }
      case types.GET_ALL_PROPERTIES.FAILED: {
        draft.allPropertyList.loading = false;
        draft.allPropertyList.error = action.error;
        break;
      }

      // case types.UPDATE_PROPERTY_STATUS.ROOT: {
      //   const newData = draft.allRequestPropertyList.data
      //     .map((record) =>
      //       record.id === action.propertyId
      //         ? {
      //             ...record,
      //             status: action.status,
      //           }
      //         : record
      //     )
      //     .filter((record) => record.status !== "published");
      //   draft.allRequestPropertyList.data = newData;
      //   break;
      // }

      case types.GET_ADMIN_DASHBOARD_INFO.REQUEST: {
        draft.adminDashboardInfo.loading = true;
        break;
      }
      case types.GET_ADMIN_DASHBOARD_INFO.SUCCEEDED: {
        draft.adminDashboardInfo.totalProperties =
          action.adminDashboardInfo.totalProperties;
        draft.adminDashboardInfo.totalPublishedProperties =
          action.adminDashboardInfo.totalPublishedProperties;
        draft.adminDashboardInfo.totalUsers =
          action.adminDashboardInfo.totalUsers;
        draft.adminDashboardInfo.loading = false;
        break;
      }
      case types.GET_ADMIN_DASHBOARD_INFO.FAILED: {
        draft.adminDashboardInfo.error = action.error;
        break;
      }

      default:
        break;
    }
  });

export default adminsReducer;
