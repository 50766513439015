import { chakra, Stack as CkStack } from "@chakra-ui/react";

export const SubscribeFormContainer = chakra(CkStack, {
  baseStyle: () => ({
    width: { base: "full", xl: "300px" },
  }),
});

export const SubscribeFormWrapper = chakra(CkStack, {
  baseStyle: () => ({
    spacing: "4",
    direction: {
      base: "column",
      md: "row",
    },
  }),
});
