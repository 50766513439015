import HTTPMethod from "./index";

class PropertiesService {
  // [GET]
  getPublishedPropertyList = ({ page, limit, queryFilter, sortBy }) =>
    HTTPMethod.get(
      `/published-properties?page=${page}&limit=${limit}${queryFilter}${
        sortBy ? `&sortBy=${sortBy}` : ""
      }`
    );
  getNewPropertyList = ({ page }) =>
    HTTPMethod.get(`/properties/new-properties?page=${page}&limit=12`);
  getPropertyDetail = ({ id }) => HTTPMethod.get(`/properties/${id}`);
  getPropertyAmenities = ({ postalCode }) =>
    HTTPMethod.get(`/properties/amenities?postalCode=${postalCode}`);
  getPropertyImagesLandingPage = () =>
    HTTPMethod.get("/properties/images-landing-page");

  // [POST]
  createProperty = ({ propertyData }) =>
    HTTPMethod.post("/properties/create", propertyData);
  createPublishedProperty = ({ publishPropertyData }) =>
    HTTPMethod.post("/published-properties/create", publishPropertyData);
  sendMessageToSeller = ({ messageData }) =>
    HTTPMethod.post("/properties/message-seller", messageData);
  getPropertyHistorical = ({ historicalData }) =>
    HTTPMethod.post("/properties/historical", historicalData);

  // [PUT]
  updateProperty = ({ propertyId, propertyData, config }) =>
    HTTPMethod.put(`/properties/edit/${propertyId}`, propertyData, config);
  updatePropertyOwnership = ({ propertyId, propertyOwnershipFile, config }) =>
    HTTPMethod.put(
      `/properties/update/${propertyId}/ownership`,
      propertyOwnershipFile,
      config
    );
}

export default new PropertiesService();
