import React, { useEffect, useRef, useState } from "react";
import { Avatar, Flex, Box } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { shallowEqual, useSelector } from "react-redux";

import bellIcon from "assets/icons/bell.svg";

import Icon from "components/Icon";
import {
  UserProfileBellIcon,
  UserProfileEmail,
  UserProfileSectionMobile,
  UserProfileUsername,
  IconButton,
  UserProfileSection,
  BellNotification,
  ChevronIconContainer,
  UserProfileSectionContainer,
} from "./userProfile.styles";
import AccountMenuDesktop from "../AccountMenuDesktop";
import TitleText from "components/TitleText";
import AccountMenuMobile from "../AccountMenuMobile";

const UserProfile = ({ mobileNav }) => {
  const userData = useSelector(
    (state) => state.authsReducer.userData,
    shallowEqual
  );

  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const accountMenuRef = useRef(null);

  const handleOutSideClick = (e) => {
    if (accountMenuRef.current && accountMenuRef.current.contains(e.target))
      return;
    else {
      setShowAccountMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
    };
  }, []);

  return (
    <>
      {/* <UserProfileBellIcon>
        <IconButton
          isRound
          icon={<Icon icon={bellIcon} width={25} height={32} />}
        />
        <BellNotification>99+</BellNotification>
      </UserProfileBellIcon> */}
      <UserProfileSection>
        <UserProfileSectionContainer>
          <Flex alignItems="center">
            <Avatar
              name={userData?.username}
              src={userData?.profileImage}
              size="md"
              marginRight={{
                base: 0,
                md: "15px",
              }}
            />
            <Flex
              cursor="pointer"
              onClick={() => setShowAccountMenu(!showAccountMenu)}>
              <UserProfileUsername>{userData?.username}</UserProfileUsername>
              <ChevronIconContainer>
                {showAccountMenu ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </ChevronIconContainer>
            </Flex>
            <UserProfileSectionMobile>
              <TitleText
                title={userData?.username}
                fontSize="16px"
                lineHeight="shorter"
                marginRight="10px"
              />
              <UserProfileEmail>{userData?.email}</UserProfileEmail>
            </UserProfileSectionMobile>
          </Flex>
          <AccountMenuMobile mobileNav={mobileNav} />
        </UserProfileSectionContainer>
      </UserProfileSection>
      {showAccountMenu && (
        <Box ref={accountMenuRef}>
          <AccountMenuDesktop setShowAccountMenu={setShowAccountMenu} />
        </Box>
      )}
    </>
  );
};

export default UserProfile;
