import {
  chakra,
  List as CkList,
  ListItem as CkListItem,
} from "@chakra-ui/react";

export const AccountMenuDesktopContainer = chakra(CkList, {
  baseStyle: () => ({
    zIndex: 100,
    width: "200px",
    background: "background.primary",
    boxShadow: "0px 4px 8px rgba(16, 35, 64, 0.2)",
    borderRadius: "8px",
    padding: "16px",
    paddingBottom: "1px",
    position: "absolute",
    top: "60px",
    left: "20px",
  }),
});

export const ListItem = chakra(CkListItem, {
  baseStyle: () => ({
    cursor: "pointer",
    display: "flex",
    marginBottom: "15px",
    alignItems: "center",
  }),
});
