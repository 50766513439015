import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const baseAxios = () => {
  httpClient.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem("token"));

    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return {
    get: (url, options = {}) => httpClient.get(url, { ...options }),
    post: (url, data, options = {}) =>
      httpClient.post(url, data, { ...options }),
    put: (url, data, options = {}) => httpClient.put(url, data, { ...options }),
    delete: (url, options = {}) => httpClient.delete(url, { ...options }),
  };
};

const HTTPMethod = baseAxios();

export default HTTPMethod;
