import React, { useEffect, lazy } from "react";
import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Redirect, useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AuthsAction from "stores/redux/actions/AuthsAction";
import * as routes from "./constants/routes";
import history from "utils/history";
import useLocalStorage from "hooks/useLocalStorage";
import Header from "components/Header";
import Footer from "components/Footer";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/index.scss";
import "./assets/styles/flag-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Lazy Pages
const CallbackFacebook = lazy(() =>
  import("./pages/callbacks/CallbackFacebook")
);

const CallbackGoogle = lazy(() => import("./pages/callbacks/CallbackGoogle"));
const CallbackResetPassword = lazy(() =>
  import("./pages/callbacks/CallbackResetPassword")
);
const Homepage = lazy(() => import("./pages/homepage"));
const LandingPage = lazy(() => import("./pages/landingPage"));
const AboutPage = lazy(() => import("./pages/aboutUs"));
const NewsPage = lazy(() => import("./pages/news"));
const BuyPropertyPage = lazy(() => import("./pages/buyPropertyPage"));
const SearchResultPage = lazy(() => import("./pages/searchResultPage"));
const PropertyDetailPage = lazy(() => import("./pages/propertyDetailPage"));
const MyAccountPage = lazy(() => import("./pages/myAccountPage"));
const MyActivityPage = lazy(() => import("./pages/myActivityPage"));
const MyPropertyPage = lazy(() => import("./pages/myPropertyPage"));
const ViewActivityPage = lazy(() =>
  import("./pages/myPropertyPage/pages/viewActivityPage")
);
const EditPropertyPage = lazy(() =>
  import("./pages/myPropertyPage/pages/editPropertyPage")
);
const SellPropertyPage = lazy(() => import("./pages/sellPropertyPage"));
const ConfirmProperty = lazy(() => import("./pages/confirmProperty"));
const AdminPropertyPage = lazy(() => import("./pages/adminPropertyPage"));

const App = () => {
  const modeAuthCallback = useSelector(
    (state) => state.authsReducer.modeAuthCallback,
    shallowEqual
  );
  const checkResetPasswordStatus = useSelector(
    (state) => state.authsReducer.checkResetPasswordStatus,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { checkAuthenticationRequest } = AuthsAction;

  const { getFromLocal } = useLocalStorage();

  const { pathname } = useLocation();

  const resetToken = getFromLocal("resetToken");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (checkResetPasswordStatus && resetToken) {
      if (pathname !== "/auth/reset-password/callback")
        history.push(
          `${routes.CALLBACK_RESET_PASSWORD}?resetToken=${getFromLocal(
            "resetToken"
          )}`
        );
    }
  }, [checkResetPasswordStatus, pathname, getFromLocal, resetToken]);

  useEffect(() => {
    dispatch(checkAuthenticationRequest());
  }, [checkAuthenticationRequest, dispatch]);

  return (
    <React.Suspense fallback={<></>}>
      {/* COMMON COMPONENT */}
      <ToastContainer />
      {!modeAuthCallback && <Header />}

      {/* ROUTES */}
      {/* ROUTES COMMON */}
      <Route exact path={routes.HOMEPAGE} component={Homepage} />
      <Route exact path={routes.LANDING_PAGE} component={LandingPage} />
      <Route exact path={routes.ABOUT_US} component={AboutPage} />

      <Route exact path={`${routes.NEWS}`}>
        <Redirect to="/" />
      </Route>
      <Route exact path={`${routes.NEWS}/:newsId`} component={NewsPage} />
      <Route exact path={routes.MY_ACCOUNT} component={MyAccountPage} />
      <Route exact path={routes.MY_ACTIVITY} component={MyActivityPage} />

      {/* ROUTES PROPERTY */}
      <Route exact path={routes.BUY_PROPERTY} component={BuyPropertyPage} />
      <Route exact path={routes.PROPERTY_SEARCH} component={SearchResultPage} />
      <Route
        exact
        path={`${routes.PROPERTY_DETAIL}/:propertyId`}
        component={PropertyDetailPage}
      />
      <Route exact path={routes.MY_PROPERTY} component={MyPropertyPage} />
      <Route
        exact
        path={`${routes.VIEW_ACTIVITY}/:propertyId`}
        component={ViewActivityPage}
      />
      <Route
        exact
        path={`${routes.EDIT_PROPERTY}/:propertyId`}
        component={EditPropertyPage}
      />
      <Route exact path={routes.SELL_PROPERTY} component={SellPropertyPage} />
      <Route
        exact
        path={`${routes.CONFIRM_PROPERTY}/:propertyPackage`}
        component={ConfirmProperty}
      />
      <Route
        exact
        path={`${routes.PUBLISH_PROPERTY}/:choosenPropertyId`}
        component={SellPropertyPage}
      />

      {/* ROUTES CALLBACK */}
      <Route
        exact
        path={routes.CALLBACK_FACEBOOK}
        component={CallbackFacebook}
      />
      <Route exact path={routes.CALLBACK_GOOGLE} component={CallbackGoogle} />
      <Route
        exact
        path={routes.CALLBACK_RESET_PASSWORD}
        component={CallbackResetPassword}
      />

      {/* ROUTES ADMIN */}
      <Route
        exact
        path={routes.ADMIN_DASHBOARD}
        component={AdminPropertyPage}
      />

      {!modeAuthCallback && <Footer />}
    </React.Suspense>
  );
};

export default App;
