import { combineReducers } from "redux";

import authsReducer from "./reducers/authsReducer";
import propertiesReducer from "./reducers/propertiesReducer";
import adminsReducer from "./reducers/adminsReducer";

const rootReducer = combineReducers({
  authsReducer,
  propertiesReducer,
  adminsReducer,
});

export default rootReducer;
