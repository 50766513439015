import HTTPMethod from "./index";

class AuthsService {
  // [GET]
  getUserDataFromSocialProvider = ({ socialProvider, token }) =>
    HTTPMethod.get(`/auth/${socialProvider}/callback?code=${token}`);
  getUserData = () => HTTPMethod.get("/users/me");
  getMyProperty = ({ page, typeStatus, sortBy }) =>
    HTTPMethod.get(
      `/users/my-property?page=${page}${
        typeStatus ? `&type=${typeStatus}` : ""
      }${sortBy ? `&sortBy=${sortBy}` : ""}`
    );
  getMyRequestProperty = () => HTTPMethod.get("/users/my-request-property");

  // [POST]
  login = ({ email, password }) =>
    HTTPMethod.post("/auth/login", {
      email,
      password,
    });
  signUp = ({ username, email, password }) =>
    HTTPMethod.post("/auth/register", {
      username,
      email,
      password,
    });
  forgotPassword = (formData) =>
    HTTPMethod.post("/auth/forgot-password", formData);
  resetPassword = ({ token, newPassword }) =>
    HTTPMethod.post("/auth/reset-password", { token, newPassword });
  verifiedResetToken = ({ resetToken }) =>
    HTTPMethod.post("/auth/verified-token", { resetToken });

  // [PUT]
  updateMyProfile = ({ updateData }) =>
    HTTPMethod.put("/users/update-profile", { ...updateData });
  updateMyPassword = ({ updatePassword }) =>
    HTTPMethod.put("/users/change-password", { ...updatePassword });
}

export default new AuthsService();
