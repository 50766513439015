import { fork, all } from "redux-saga/effects";

import authsSaga from "./authsSaga";
import propertiesSaga from "./propertiesSaga";
import adminsSaga from "./adminsSaga";

export default function* rootSaga() {
  yield all([fork(authsSaga)]);
  yield all([fork(propertiesSaga)]);
  yield all([fork(adminsSaga)]);
}
