import {
  Input,
  FormControl,
  Flex,
  Textarea,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";

import { disabledStyle } from "./constants";

import { FormLabel, InputIconContainer } from "./inputField.styles";
import NormalText from "components/NormalText";

const InputField = ({
  type = "text",
  placeholder = "",
  label = "",
  onSubmit,
  usingEnterInput,
  formControlStyle = {},
  formLabelStyle = {},
  leftInputIcon,
  leftText,
  rightInputIcon,
  showingFocusBorder = true,
  usingTextArea,
  usingSelectNumber,
  isDisabled,
  error,
  touched,
  name,
  onBlur,
  onChange,
  getCardNumberInputProps,
  ...rest
}) => {
  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  return (
    <FormControl isInvalid={error && touched} {...formControlStyle}>
      {label && <FormLabel {...formLabelStyle}>{label}</FormLabel>}
      <Flex alignItems="center">
        {leftInputIcon && (
          <InputIconContainer>{leftInputIcon}</InputIconContainer>
        )}
        {usingTextArea ? (
          <Textarea
            name={name}
            type={type}
            placeholder={placeholder}
            onKeyDown={usingEnterInput ? (e) => handleOnKeyDown(e) : () => {}}
            focusBorderColor={!showingFocusBorder && "transparent"}
            _disabled={isDisabled && disabledStyle}
            disabled={isDisabled}
            background={error && touched ? "background.error" : "white"}
            onBlur={onBlur}
            onChange={onChange}
            {...rest}
          />
        ) : (
          <InputGroup flexDirection="column">
            {leftText && (
              <InputLeftElement
                top="-1px"
                pointerEvents="none"
                children={
                  <NormalText
                    text={leftText}
                    bold={!isDisabled}
                    color={isDisabled && "text.third"}
                  />
                }
              />
            )}
            <Input
              name={name}
              type={type}
              placeholder={placeholder}
              onKeyDown={usingEnterInput ? (e) => handleOnKeyDown(e) : () => {}}
              focusBorderColor={!showingFocusBorder && "transparent"}
              _disabled={isDisabled && disabledStyle}
              disabled={isDisabled}
              background={error && touched ? "background.error" : "white"}
              onBlur={onBlur}
              onChange={onChange}
              {...getCardNumberInputProps?.({
                onBlur,
                onChange,
              })}
              {...rest}
            />
            {rightInputIcon && (
              <InputRightElement
                pointerEvents="none"
                children={rightInputIcon}
              />
            )}
            <FormErrorMessage>{error}</FormErrorMessage>
          </InputGroup>
        )}
      </Flex>
    </FormControl>
  );
};

export default InputField;
