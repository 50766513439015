import * as routes from "constants/routes";

export const navLinkList = [
  {
    path: routes.BUY_PROPERTY,
    label: "Buy",
  },
  {
    path: routes.SELL_PROPERTY,
    label: "Sell",
  },
  {
    path: routes.ABOUT_US,
    label: "About Us",
  },
];
