import { Box, Link, Stack, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Link as ReachLink } from "react-router-dom";

import { socialLinkList } from "components/Footer/constants";
import * as routes from "constants/routes";

import TitleText from "components/TitleText";

const LinkGrid = () => (
  <SimpleGrid
    columns={{
      base: 2,
      md: 3,
    }}
    flex="1"
    spacing={{ base: "8", md: "15", lg: "25" }}>
    <Box minW="130px">
      <TitleText
        title="Company"
        fontSize="18px"
        lineHeight="24px"
        marginBottom="16px"
      />
      <Stack>
        <Link as={ReachLink} to={routes.ABOUT_US}>
          About Us
        </Link>
        <Link>Privacy Policy</Link>
      </Stack>
    </Box>
    <Box minW="130px">
      <TitleText
        title="Support"
        fontSize="18px"
        lineHeight="24px"
        marginBottom="16px"
      />
      <Stack>
        <Link>Help</Link>
        <Link>FAQ</Link>
        <Link>Contact Us</Link>
      </Stack>
    </Box>
    <Box minW="130px">
      <TitleText
        title="Stay Connected"
        fontSize="18px"
        lineHeight="24px"
        marginBottom="16px"
      />
      <Stack>
        {socialLinkList.map((social) => (
          <Link
            key={social.label}
            target="_blank"
            href={social.link}
            _focus={{
              boxShadow: "none",
            }}>
            {social.label}
          </Link>
        ))}
      </Stack>
    </Box>
  </SimpleGrid>
);

export default LinkGrid;
