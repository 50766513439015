import {
  chakra,
  Flex as CkFlex,
  IconButton as CkIconButton,
  HStack as CkHStack,
  Text as CkText,
  List as CkList,
} from "@chakra-ui/react";

export const UserProfileBellIcon = chakra(CkFlex, {
  baseStyle: () => ({
    display: {
      base: "none",
      md: "flex",
    },
    position: "relative",
    order: {
      base: 2,
      md: 1,
    },
  }),
});

export const BellNotification = chakra(CkFlex, {
  baseStyle: () => ({
    background: "background.primary",
    width: "24px",
    height: "24px",
    borderRadius: "100%",
    color: "text.secondary",
    fontSize: "10px",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
  }),
});

export const IconButton = chakra(CkIconButton, {
  baseStyle: () => ({
    size: "sm",
    fontSize: "xl",
    background: "transparent",
  }),
});

export const UserProfileSection = chakra(CkHStack, {
  baseStyle: () => ({
    spacing: 3,
    order: {
      base: 1,
      md: 2,
    },
    flex: "1",
  }),
});

export const UserProfileSectionMobile = chakra(CkList, {
  baseStyle: () => ({
    marginLeft: "15px",
    display: {
      base: "flex",
      md: "none",
    },
    flexDirection: "column",
  }),
});

export const UserProfileUsername = chakra(CkText, {
  baseStyle: () => ({
    display: {
      base: "none",
      md: "flex",
    },
    color: "text.secondary",
    fontWeight: "bold",
    fontSize: "16px",
  }),
});

export const UserProfileEmail = chakra(CkText, {
  baseStyle: () => ({
    fontSize: "sm",
    lineHeight: "shorter",
    opacity: 0.7,
  }),
});

export const ChevronIconContainer = chakra(CkFlex, {
  baseStyle: () => ({
    display: {
      base: "none",
      md: "flex",
    },
    alignItems: "center",
    marginLeft: "15px",
  }),
});

export const UserProfileSectionContainer = chakra(CkFlex, {
  baseStyle: () => ({
    flexDirection: {
      base: "column",
      md: "row",
    },
  }),
});
