import * as types from "constants/types";

class AuthsAction {
  checkAuthenticationRequest = () => ({
    type: types.CHECK_AUTHENTICATION.REQUEST,
  });
  checkAuthenticationSucceeded = () => ({
    type: types.CHECK_AUTHENTICATION.SUCCEEDED,
  });
  checkAuthenticationFailed = () => ({
    type: types.CHECK_AUTHENTICATION.FAILED,
  });

  getDataFromSocialProviderRequest = ({ socialProvider, token }) => ({
    type: types.GET_DATA_FROM_SOCIAL_PROVIDER.REQUEST,
    socialProvider,
    token,
  });
  getDataFromSocialProviderSucceeded = ({ token, userData }) => ({
    type: types.GET_DATA_FROM_SOCIAL_PROVIDER.SUCCEEDED,
    token,
    userData,
  });
  getDataFromSocialProviderFailed = (error) => ({
    type: types.GET_DATA_FROM_SOCIAL_PROVIDER.FAILED,
    error,
  });

  getUserDataRequest = () => ({
    type: types.GET_USER_DATA.REQUEST,
  });
  getUserDataSucceeded = ({ userData }) => ({
    type: types.GET_USER_DATA.SUCCEEDED,
    userData,
  });
  getUserDataFailed = (error) => ({
    type: types.GET_USER_DATA.FAILED,
    error,
  });

  loginRequest = (loginData, cb, cbError) => ({
    type: types.LOGIN.REQUEST,
    ...loginData,
    cb,
    cbError,
  });
  loginSucceeded = ({ token, userData }) => ({
    type: types.LOGIN.SUCCEEDED,
    token,
    userData,
  });
  loginFailed = (error) => ({
    type: types.LOGIN.FAILED,
    error,
  });

  signUpRequest = (signUpData, cb, cbError) => ({
    type: types.SIGN_UP.REQUEST,
    ...signUpData,
    cb,
    cbError,
  });
  signUpSucceeded = ({ token, userData }) => ({
    type: types.SIGN_UP.SUCCEEDED,
    token,
    userData,
  });
  signUpFailed = (error) => ({
    type: types.SIGN_UP.FAILED,
    error,
  });

  signOut = () => ({
    type: types.SIGN_OUT.ROOT,
  });

  updateMyProfileRequest = (userData, cb) => ({
    type: types.UPDATE_MY_PROFILE.REQUEST,
    ...userData,
    cb,
  });

  updateMyProfileSucceeded = (userData) => ({
    type: types.UPDATE_MY_PROFILE.SUCCEEDED,
    userData,
  });
  updateMyProfileFailed = (error) => ({
    type: types.UPDATE_MY_PROFILE.FAILED,
    error,
  });

  updateMyPasswordRequest = (passwordData, cb, cbError) => ({
    type: types.UPDATE_MY_PASSWORD.REQUEST,
    ...passwordData,
    cb,
    cbError,
  });
  updateMyPasswordSucceeded = () => ({
    type: types.UPDATE_MY_PASSWORD.SUCCEEDED,
  });
  updateMyPasswordFailed = (error) => ({
    type: types.UPDATE_MY_PASSWORD.FAILED,
    error,
  });

  getMyPropertyRequest = (page, typeStatus, sortBy) => ({
    type: types.GET_MY_PROPERTY.REQUEST,
    page,
    typeStatus,
    sortBy,
  });
  getMyPropertySucceeded = (myPropertyList, total) => ({
    type: types.GET_MY_PROPERTY.SUCCEEDED,
    myPropertyList,
    total,
  });
  getMyPropertyFailed = (error) => ({
    type: types.GET_MY_PROPERTY.FAILED,
    error,
  });

  forgotPassword = (email, cb) => ({
    type: types.FORGOT_PASSWORD.ROOT,
    email,
    cb,
  });

  resetPasswordRequest = (token, newPassword, cb, cbError) => ({
    type: types.RESET_PASSWORD.REQUEST,
    token,
    newPassword,
    cb,
    cbError,
  });
  resetPasswordSucceeded = () => ({
    type: types.RESET_PASSWORD.SUCCEEDED,
  });
  resetPasswordFailed = (error) => ({
    type: types.RESET_PASSWORD.FAILED,
    error,
  });

  verifiedResetToken = (resetToken) => ({
    type: types.VERIFIED_RESET_TOKEN.ROOT,
    resetToken,
  });

  getMyRequestPropertyRequest = () => ({
    type: types.GET_MY_REQUEST_PROPERTY.REQUEST,
  });
  getMyRequestPropertySucceeded = (myRequestPropertyList) => ({
    type: types.GET_MY_REQUEST_PROPERTY.SUCCEEDED,
    myRequestPropertyList,
  });
  getMyRequestPropertyFailed = (error) => ({
    type: types.GET_MY_REQUEST_PROPERTY.FAILED,
    error,
  });
}

export default new AuthsAction();
