import { takeLatest, put, call, delay } from "redux-saga/effects";

import PropertiesService from "services/PropertiesService";

import UseLocalStorage from "hooks/useLocalStorage";
import * as types from "constants/types";

import PropertiesAction from "stores/redux/actions/PropertiesAction";
import AuthsAction from "stores/redux/actions/AuthsAction";

function* getPublishedPropertyList({ page, limit, queryFilter = {}, sortBy }) {
  try {
    const {
      location,
      propertyType,
      minPrice,
      maxPrice,
      bedRange,
      bathRange,
      minArea,
      maxArea,
      unitAttribute,
      facilities,
      hdbTowns,
      districts,
    } = queryFilter;
    let formatQueryFilter = "";

    if (location && !hdbTowns && !districts) formatQueryFilter += `&location=${location}`;
    if (hdbTowns) {
      formatQueryFilter += `&hdbTowns=${hdbTowns}`;
    }
    if (districts) {
      formatQueryFilter += `&districts=${districts}`;
    }
    if (propertyType) formatQueryFilter += `&propertyType=${propertyType}`;
    if (minPrice)
      formatQueryFilter += `&minPrice=${
        minPrice === "No Min" ? "no_min" : minPrice
      }`;
    if (maxPrice)
      formatQueryFilter += `&maxPrice=${
        maxPrice === "No Max" ? "no_max" : maxPrice
      }`;
    if (bedRange) formatQueryFilter += `&bedRange=${bedRange}`;
    if (bathRange) formatQueryFilter += `&bathRange=${bathRange}`;
    if (minArea) formatQueryFilter += `&minArea=${minArea}`;
    if (maxArea) formatQueryFilter += `&maxArea=${maxArea}`;
    if (unitAttribute)
      formatQueryFilter += `&unitAttribute=${JSON.stringify(
        unitAttribute?.map((record) => record.value)
      )}`;
    if (facilities)
      formatQueryFilter += `&facilities=${JSON.stringify(
        facilities?.map((record) => record.value)
      )}`;

    const { data } = yield call(PropertiesService.getPublishedPropertyList, {
      page,
      limit,
      queryFilter: formatQueryFilter,
      sortBy,
    });

    yield put(
      PropertiesAction.getPublishedPropertyListSucceeded(
        data.results,
        data.total
      )
    );
  } catch (error) {
    yield put(
      PropertiesAction.getPublishedPropertyListFailed(
        error?.response?.data?.msg
      )
    );
    console.log(error);
  }
}

function* getPropertyDetail({ id }) {
  try {
    const { data } = yield call(PropertiesService.getPropertyDetail, { id });

    yield put(PropertiesAction.getPropertyDetailSucceeded(data));
  } catch (error) {
    yield put(
      PropertiesAction.getPropertyDetailFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* getPropertyAmenities({ postalCode }) {
  try {
    const { data } = yield call(PropertiesService.getPropertyAmenities, {
      postalCode,
    });

    yield put(PropertiesAction.getPropertyAmenitiesSucceeded(data));
  } catch (error) {
    yield put(
      PropertiesAction.getPropertyAmenitiesFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* getPropertyHistorical({ historicalData }) {
  try {
    const { data } = yield call(PropertiesService.getPropertyHistorical, {
      historicalData,
    });

    yield put(PropertiesAction.getPropertyHistoricalSucceeded(data));
  } catch (error) {
    yield put(
      PropertiesAction.getPropertyHistoricalFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* createProperty({ propertyData, cb }) {
  const { saveToLocal } = UseLocalStorage();

  try {
    const { data } = yield call(PropertiesService.createProperty, {
      propertyData,
    });

    yield saveToLocal("propertyId", data?.id);
    yield put(PropertiesAction.createPropertySucceeded());
    yield put(AuthsAction.getUserDataRequest());
    yield cb?.(data?.id);
  } catch (error) {
    yield put(
      PropertiesAction.createPropertyFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* updateProperty({ propertyId, propertyData, usingDelay, cb }) {
  try {
    if (usingDelay) yield delay(2000);

    // if (
    //   propertyData?.propertyOwnership === "" ||
    //   (propertyData?.propertyOwnership !== "" &&
    //     typeof propertyData?.propertyOwnership !== "string")
    // ) {
    //   const dataPropertyOwnership = new FormData();

    //   dataPropertyOwnership.append(
    //     "ownershipFile",
    //     propertyData?.propertyOwnership
    //   );

    //   yield call(PropertiesService.updatePropertyOwnership, {
    //     propertyId,
    //     propertyOwnershipFile: dataPropertyOwnership,
    //     config: {
    //       headers: { "Content-Type": "multipart/form-data" },
    //     },
    //   });
    // }

    yield delete propertyData?.propertyOwnership;
    yield call(PropertiesService.updateProperty, {
      propertyId,
      propertyData,
    });
    yield put(PropertiesAction.updatePropertySucceeded());
    yield put(AuthsAction.getUserDataRequest());
    yield cb?.();
  } catch (error) {
    yield put(
      PropertiesAction.createPropertyFailed(error?.response?.data?.msg || error)
    );
    console.log(error);
  }
}

function* createPublishedProperty({ publishPropertyData }) {
  try {
    yield call(PropertiesService.createPublishedProperty, {
      publishPropertyData,
    });
  } catch (error) {
    console.log(error);
  }
}

function* sendMessageToSeller({ messageData }) {
  try {
    yield call(PropertiesService.sendMessageToSeller, { messageData });
  } catch (error) {
    console.log(error);
  }
}

function* getPropertyImagesLandingPage() {
  try {
    const { data } = yield call(PropertiesService.getPropertyImagesLandingPage);

    yield put(PropertiesAction.getPropertyImagesLandingPageSucceeded(data));
  } catch (error) {
    yield put(
      PropertiesAction.getPropertyImagesLandingPageFailed(
        error?.response?.data?.msg || error
      )
    );
    console.log(error);
  }
}

function* getSellPropertyDetail({ id }) {
  try {
    const { data } = yield call(PropertiesService.getPropertyDetail, { id });

    yield put(PropertiesAction.getSellPropertyDetailSucceeded(data));
  } catch (error) {
    yield put(
      PropertiesAction.getSellPropertyDetailFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

export default function* propertiesSaga() {
  yield takeLatest(
    types.GET_PUBLISHED_PROPERTY_LIST.REQUEST,
    getPublishedPropertyList
  );
  yield takeLatest(types.GET_PROPERTY_DETAIL.REQUEST, getPropertyDetail);
  yield takeLatest(types.GET_PROPERTY_AMENITIES.REQUEST, getPropertyAmenities);
  yield takeLatest(
    types.GET_PROPERTY_HISTORICAL.REQUEST,
    getPropertyHistorical
  );
  yield takeLatest(types.CREATE_PROPERTY.REQUEST, createProperty);
  yield takeLatest(types.UPDATE_PROPERTY.REQUEST, updateProperty);
  yield takeLatest(types.CREATE_PUBLISH_PROPERTY.ROOT, createPublishedProperty);
  yield takeLatest(types.SEND_MESSAGE_TO_SELLER.ROOT, sendMessageToSeller);
  yield takeLatest(
    types.GET_PROPERTY_IMAGES_LANDING_PAGE.REQUEST,
    getPropertyImagesLandingPage
  );
  yield takeLatest(
    types.GET_SELL_PROPERTY_DETAIL.REQUEST,
    getSellPropertyDetail
  );
}
