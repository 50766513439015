import React, { useState } from "react";

import LoginPopup from "./components/LoginPopup";
import ResetPasswordPopup from "./components/ResetPasswordPopup";
import SignUpPopup from "./components/SignUpPopup";

const getAuthMode = (title) => {
  switch (title) {
    case "Sign In":
      return "login";

    case "Sign Up":
      return "signUp";

    case "Reset Password":
      return "resetPassword";

    default:
      break;
  }
};

const AuthenticationPopup = ({ title, closeModal, redirectTo }) => {
  const [authMode, setAuthMode] = useState(getAuthMode(title));

  return (
    <>
      {authMode === "resetPassword" ? (
        <ResetPasswordPopup closeModal={closeModal} />
      ) : authMode === "login" ? (
        <LoginPopup
          closeModal={closeModal}
          setAuthMode={setAuthMode}
          redirectTo={redirectTo}
        />
      ) : (
        <SignUpPopup
          closeModal={closeModal}
          setAuthMode={setAuthMode}
          redirectTo={redirectTo}
        />
      )}
    </>
  );
};

export default AuthenticationPopup;
