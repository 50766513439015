import { useMediaQuery, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router";

import { useSelector, shallowEqual } from "react-redux";

import { navLinkList } from "./constants";
import history from "utils/history";
import * as routes from "constants/routes";

import useModal from "hooks/useModal";

import logoIcon from "assets/images/logo_title.png";

import { HeaderContainer, Center, AuthButtonContainer } from "./header.styles";
import Navbar from "./components/Navbar";
import NavLink from "./components/NavLink/index";
import AuthenticationPopup from "components/AuthenticationPopup";
import UserProfile from ".//components/UserProfile";
import ButtonField from "components/ButtonField";
import Icon from "components/Icon";

const Header = () => {
  const checkAuthentication = useSelector(
    (state) => state.authsReducer.checkAuthentication,
    shallowEqual
  );

  const { open, close, Dialog } = useModal({
    modalBody: AuthenticationPopup,
    usingFooter: false,
    usingHeader: false,
  });

  const location = useLocation();
  const [checkMobileView] = useMediaQuery("(max-width: 767px)");
  const mobileNav = useDisclosure();

  const handleNavLink = (path) => {
    mobileNav.onClose();

    if (checkAuthentication || path !== routes.SELL_PROPERTY)
      history.push(path);
    else
      open({
        title: "Sign In",
        redirectTo: path,
      });
  };

  return (
    <>
      <HeaderContainer>
        <Navbar mobileNav={mobileNav} id='nav-bar'>
          <Navbar.Brand id='nav-bar-brand'>
            <Center
              margin="0"
              onClick={() => {
                mobileNav.onClose();
                history.push(routes.LANDING_PAGE);
              }}>
              <Icon icon={logoIcon} width={195} height={40} />
            </Center>
            {checkMobileView && !checkAuthentication && !mobileNav.isOpen && (
              <ButtonField
              fontSize="sm"
              padding="0.2rem 0.5rem"
                onClick={() => {
                  mobileNav.onClose();
                  open({
                    title: "Sign Up",
                  });
                }}
                buttonText="Sign Up"
                marginLeft="auto"
              />
            )}
          </Navbar.Brand>
          <Navbar.Links>
            {navLinkList.map((navLink, index) => (
              <NavLink
                isActive={location?.pathname === navLink.path}
                key={index}
                onClick={() => handleNavLink(navLink.path)}>
                {navLink.label}
              </NavLink>
            ))}
          </Navbar.Links>
          {checkAuthentication ? (
            <Navbar.UserProfile>
              <UserProfile mobileNav={mobileNav} />
            </Navbar.UserProfile>
          ) : (
            <Navbar.AuthButton>
              <AuthButtonContainer>
                <ButtonField
                  variant="outlined"
                  border="none"
                  onClick={() => {
                    mobileNav.onClose();
                    open({
                      title: "Sign In",
                    });
                  }}
                  color="text.secondary"
                  buttonText="Sign In"
                  hoverBackgroundColor="transparent"
                />
                <ButtonField
                  onClick={() => {
                    mobileNav.onClose();
                    open({
                      title: "Sign Up",
                    });
                  }}
                  buttonText="Sign Up"
                />
              </AuthButtonContainer>
            </Navbar.AuthButton>
          )}
        </Navbar>
      </HeaderContainer>
      {Dialog(close)}
    </>
  );
};

export default Header;
