import React from "react";

import { CopyRightContainer } from "./copyRight.styles";
import NormalText from "components/NormalText";

const CopyRight = () => (
  <CopyRightContainer>
    <NormalText
      text={`© ${new Date().getFullYear()} Property Genie. All rights reserved.`}
      color="text.third"
      fontSizeProps="16px"
      fontSizeMobileProps="12px"
    />
  </CopyRightContainer>
);

export default CopyRight;
