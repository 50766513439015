import * as types from "constants/types";

class PropertiesAction {
  getPublishedPropertyListRequest = (page, limit, queryFilter, sortBy) => ({
    type: types.GET_PUBLISHED_PROPERTY_LIST.REQUEST,
    page,
    limit,
    queryFilter,
    sortBy,
  });
  getPublishedPropertyListSucceeded = (publishedPropertyList, total) => ({
    type: types.GET_PUBLISHED_PROPERTY_LIST.SUCCEEDED,
    publishedPropertyList,
    total,
  });
  getPublishedPropertyListFailed = (error) => ({
    type: types.GET_PUBLISHED_PROPERTY_LIST.FAILED,
    error,
  });

  getPropertyDetailRequest = (id) => ({
    type: types.GET_PROPERTY_DETAIL.REQUEST,
    id,
  });
  getPropertyDetailSucceeded = (propertyDetail) => ({
    type: types.GET_PROPERTY_DETAIL.SUCCEEDED,
    propertyDetail,
  });
  getPropertyDetailFailed = (error) => ({
    type: types.GET_PROPERTY_DETAIL.FAILED,
    error,
  });
  getPropertyDetailReset = () => ({
    type: types.GET_PROPERTY_DETAIL.RESET,
  });

  createPropertyRequest = (propertyData, cb) => ({
    type: types.CREATE_PROPERTY.REQUEST,
    propertyData,
    cb,
  });
  createPropertySucceeded = () => ({
    type: types.CREATE_PROPERTY.SUCCEEDED,
  });
  createPropertyFailed = (error) => ({
    type: types.CREATE_PROPERTY.FAILED,
    error,
  });

  updatePropertyRequest = (propertyId, propertyData, usingDelay, cb) => ({
    type: types.UPDATE_PROPERTY.REQUEST,
    propertyId,
    propertyData,
    usingDelay,
    cb,
  });
  updatePropertySucceeded = () => ({
    type: types.UPDATE_PROPERTY.SUCCEEDED,
  });
  updatePropertyFailed = (error) => ({
    type: types.UPDATE_PROPERTY.FAILED,
    error,
  });

  getPropertyAmenitiesRequest = (postalCode) => ({
    type: types.GET_PROPERTY_AMENITIES.REQUEST,
    postalCode,
  });
  getPropertyAmenitiesSucceeded = (propertyAmenityList) => ({
    type: types.GET_PROPERTY_AMENITIES.SUCCEEDED,
    propertyAmenityList,
  });
  getPropertyAmenitiesFailed = (error) => ({
    type: types.GET_PROPERTY_AMENITIES.FAILED,
    error,
  });

  getPropertyHistoricalRequest = (historicalData) => ({
    type: types.GET_PROPERTY_HISTORICAL.REQUEST,
    historicalData,
  });
  getPropertyHistoricalSucceeded = (propertyHistoricalList) => ({
    type: types.GET_PROPERTY_HISTORICAL.SUCCEEDED,
    propertyHistoricalList,
  });
  getPropertyHistoricalFailed = (error) => ({
    type: types.GET_PROPERTY_HISTORICAL.FAILED,
    error,
  });

  createPublishedProperty = (publishPropertyData) => ({
    type: types.CREATE_PUBLISH_PROPERTY.ROOT,
    publishPropertyData,
  });

  sendMessageToSeller = (messageData) => ({
    type: types.SEND_MESSAGE_TO_SELLER.ROOT,
    messageData,
  });

  getPropertyImagesLandingPageRequest = () => ({
    type: types.GET_PROPERTY_IMAGES_LANDING_PAGE.REQUEST,
  });
  getPropertyImagesLandingPageSucceeded = (propertyLandingPageImageList) => ({
    type: types.GET_PROPERTY_IMAGES_LANDING_PAGE.SUCCEEDED,
    propertyLandingPageImageList,
  });
  getPropertyImagesLandingPageFailed = (error) => ({
    type: types.GET_PROPERTY_IMAGES_LANDING_PAGE.FAILED,
    error,
  });

  getSellPropertyDetailRequest = (id) => ({
    type: types.GET_SELL_PROPERTY_DETAIL.REQUEST,
    id,
  });
  getSellPropertyDetailSucceeded = (sellPropertyDetail) => ({
    type: types.GET_SELL_PROPERTY_DETAIL.SUCCEEDED,
    sellPropertyDetail,
  });
  getSellPropertyDetailFailed = (error) => ({
    type: types.GET_SELL_PROPERTY_DETAIL.FAILED,
    error,
  });
  getSellPropertyDetailReset = () => ({
    type: types.GET_SELL_PROPERTY_DETAIL.RESET,
  });
}

export default new PropertiesAction();
