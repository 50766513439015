import { Flex } from "@chakra-ui/react";
import React from "react";
import { Form, Formik } from "formik";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import AuthsAction from "stores/redux/actions/AuthsAction";

import useNotification from "hooks/useNotification";

import {
  formControlStyle,
  loginValidation,
} from "components/AuthenticationPopup/constants";
import history from "utils/history";

import googleIcon from "assets/icons/google.svg";
import facebookIcon from "assets/icons/facebook.svg";

import {
  ButtonSocial,
  LoginPopupContainer,
  DividerText,
  DividerContainer,
  ForgotPasswordText,
  Text,
} from "./loginPopup.styles";
import Icon from "components/Icon";
import InputField from "components/InputField";
import { SignInButton } from "./loginPopup.styles";
import TitleText from "components/TitleText";
import Divider from "components/Divider";
import NormalText from "components/NormalText";

const LoginPopup = ({ setAuthMode, closeModal, redirectTo }) => {
  const loading = useSelector(
    (state) => state.authsReducer.loading,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { loginRequest } = AuthsAction;

  const { openNotificationError } = useNotification();

  const handleLoginError = (error) => openNotificationError(error);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginValidation}
      onSubmit={(values, actions) => {
        const handleLoginSucced = () => {
          if (redirectTo) history.push(redirectTo);

          closeModal();
          actions.resetForm();
        };

        dispatch(loginRequest(values, handleLoginSucced, handleLoginError));
      }}>
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <TitleText
            position="absolute"
            top={18}
            title="Sign In"
            width="max-content"
          />
          <LoginPopupContainer>
            <ButtonSocial
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`)
              }
              leftIcon={<Icon icon={googleIcon} size={24} />}>
              Sign In with Google
            </ButtonSocial>
            <ButtonSocial
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/auth/facebook`)
              }
              leftIcon={<Icon icon={facebookIcon} size={24} />}>
              Sign In with Facebook
            </ButtonSocial>
            <DividerContainer>
              <Divider />
              <DividerText>Or</DividerText>
              <Divider />
            </DividerContainer>
            <InputField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlStyle={formControlStyle}
            />
            <InputField
              type="password"
              name="password"
              label="Password"
              placeholder="At least 8 characters"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlStyle={formControlStyle}
            />
            <ForgotPasswordText onClick={() => setAuthMode("resetPassword")}>
              Forgot Password?
            </ForgotPasswordText>
            <SignInButton
              type="submit"
              buttonText="Sign In"
              loading={loading}
              hoverBackgroundColor="background.primary"
              disabled={Object.keys(errors).length > 0}
            />
            <Flex justifyContent="center">
              <NormalText
                text="Don’t have an account?"
                bold
                fontSize="16px"
                marginRight="5px"
              />
              <Text onClick={() => setAuthMode("signUp")}>Sign Up</Text>
            </Flex>
          </LoginPopupContainer>
        </Form>
      )}
    </Formik>
  );
};

export default LoginPopup;
