import { extendTheme } from "@chakra-ui/react";
import {
  FOURTH_BACKGROUND,
  FOURTH_TEXT,
  GREY_100_BACKGROUND,
  GREY_250_BACKGROUND,
  GREY_500_BACKGROUND,
  PRIMARY_BACKGROUND,
  PRIMARY_TEXT,
  SECONDARY_TEXT,
  SECONDARY_BACKGROUND,
  THIRD_TEXT,
  FIFTH_BACKGROUND,
  ERROR_BACKGROUND,
  FIFTH_TEXT,
  ERROR_TEXT,
  SIXTH_BACKGROUND,
} from "./globalStyles";

const breakpoints = {
  sm: '30em',
  md: '48em',
  tb: '50em',
  lg: '64em',
  xl: '80em',
  '2xl': '96em',
};

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        background: "white",
        fontFamily: "Montserrat, sans-serif",
        color: SECONDARY_TEXT,
      },
    },
  },
  colors: {
    text: {
      primary: PRIMARY_TEXT,
      secondary: SECONDARY_TEXT,
      third: THIRD_TEXT,
      fourth: FOURTH_TEXT,
      fifth: FIFTH_TEXT,
      error: ERROR_TEXT,
    },
    background: {
      primary: PRIMARY_BACKGROUND,
      secondary: SECONDARY_BACKGROUND,
      fourth: FOURTH_BACKGROUND,
      fifth: FIFTH_BACKGROUND,
      sixth: SIXTH_BACKGROUND,
      grey: {
        100: GREY_100_BACKGROUND,
        250: GREY_250_BACKGROUND,
        500: GREY_500_BACKGROUND,
      },
      error: ERROR_BACKGROUND,
    },
  },
  fonts: {
    heading: "Montserrat, sans-serif",
    body: "Montserrat, sans-serif",
  },
  breakpoints: breakpoints,
});

export default theme;
