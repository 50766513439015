import { chakra, Box as CkBox, Text as CkText } from "@chakra-ui/react";

export const ListItem = chakra(CkBox, {
  baseStyle: () => ({
    display: "flex",
    marginBottom: "15px",
  }),
});

export const ListItemText = chakra(CkText, {
  baseStyle: () => ({
    marginLeft: "10px",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
  }),
});

export const AccountMenuMobileContainer = chakra(CkBox, {
  baseStyle: () => ({
    marginTop: "16px",
    display: {
      base: "block",
      md: "none",
    },
  }),
});
