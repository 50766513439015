import produce from "immer";

import * as types from "constants/types";

const initialState = {
  publishedPropertyList: {
    loading: false,
    data: [],
    total: 0,
    error: "",
  },
  propertyDetail: {
    loading: false,
    data: {},
    error: "",
  },
  propertyAmenityList: {
    loading: false,
    data: [],
    error: "",
  },
  propertyHistoricalList: {
    loading: false,
    data: [],
    blockList: [],
    projectNameList: [],
    error: "",
  },
  propertyLandingPageImageList: {
    loading: false,
    data: [],
    error: "",
  },
  sellPropertyDetail: {
    loading: false,
    data: {},
    error: "",
  },
};

const propertiesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PUBLISHED_PROPERTY_LIST.REQUEST: {
        draft.publishedPropertyList.loading = true;
        break;
      }
      case types.GET_PUBLISHED_PROPERTY_LIST.SUCCEEDED: {
        draft.publishedPropertyList.data = action.publishedPropertyList;
        draft.publishedPropertyList.total = action.total;
        draft.publishedPropertyList.loading = false;
        draft.publishedPropertyList.error = "";
        break;
      }
      case types.GET_PUBLISHED_PROPERTY_LIST.FAILED: {
        draft.publishedPropertyList.loading = false;
        draft.publishedPropertyList.error = action.error;
        break;
      }

      case types.CREATE_PROPERTY.REQUEST:
      case types.UPDATE_PROPERTY.REQUEST: {
        draft.propertyDetail.loading = true;
        break;
      }
      case types.CREATE_PROPERTY.SUCCEEDED:
      case types.UPDATE_PROPERTY.SUCCEEDED: {
        draft.propertyDetail.loading = false;
        draft.propertyDetail.error = "";
        break;
      }
      case types.CREATE_PROPERTY.FAILED:
      case types.UPDATE_PROPERTY.FAILED: {
        draft.propertyDetail.loading = false;
        draft.propertyDetail.error = action.error;
        break;
      }

      case types.GET_PROPERTY_DETAIL.REQUEST: {
        draft.propertyDetail.loading = true;
        break;
      }
      case types.GET_PROPERTY_DETAIL.SUCCEEDED: {
        draft.propertyDetail.data = action.propertyDetail;
        draft.propertyDetail.loading = false;
        draft.propertyDetail.error = "";
        break;
      }
      case types.GET_PROPERTY_DETAIL.FAILED: {
        draft.propertyDetail.loading = false;
        draft.propertyDetail.error = action.error;
        break;
      }
      case types.GET_PROPERTY_DETAIL.RESET: {
        draft.propertyDetail.data = {};
        break;
      }

      case types.GET_PROPERTY_AMENITIES.REQUEST: {
        draft.propertyAmenityList.loading = true;
        break;
      }
      case types.GET_PROPERTY_AMENITIES.SUCCEEDED: {
        draft.propertyAmenityList.data = action.propertyAmenityList;
        draft.propertyAmenityList.loading = false;
        draft.propertyAmenityList.error = "";
        break;
      }
      case types.GET_PROPERTY_AMENITIES.FAILED: {
        draft.propertyAmenityList.loading = false;
        draft.propertyAmenityList.error = action.error;
        break;
      }

      case types.GET_PROPERTY_HISTORICAL.REQUEST: {
        draft.propertyHistoricalList.loading = true;
        break;
      }
      case types.GET_PROPERTY_HISTORICAL.SUCCEEDED: {
        draft.propertyHistoricalList.data =
          action.propertyHistoricalList.results;
        draft.propertyHistoricalList.blockList =
          action.propertyHistoricalList.blockList || [];
        draft.propertyHistoricalList.projectNameList =
          action.propertyHistoricalList.projectNameList || [];
        draft.propertyHistoricalList.loading = false;
        draft.propertyHistoricalList.error = "";
        break;
      }
      case types.GET_PROPERTY_HISTORICAL.FAILED: {
        draft.propertyHistoricalList.loading = false;
        draft.propertyHistoricalList.error = action.error;
        break;
      }

      case types.GET_PROPERTY_IMAGES_LANDING_PAGE.REQUEST: {
        draft.propertyLandingPageImageList.loading = true;
        break;
      }
      case types.GET_PROPERTY_IMAGES_LANDING_PAGE.SUCCEEDED: {
        draft.propertyLandingPageImageList.data =
          action.propertyLandingPageImageList;
        draft.propertyLandingPageImageList.loading = false;
        break;
      }
      case types.GET_PROPERTY_IMAGES_LANDING_PAGE.FAILED: {
        draft.propertyLandingPageImageList.loading = false;
        draft.propertyLandingPageImageList.error = action.error;
        break;
      }

      case types.GET_SELL_PROPERTY_DETAIL.REQUEST: {
        draft.sellPropertyDetail.loading = true;
        break;
      }
      case types.GET_SELL_PROPERTY_DETAIL.SUCCEEDED: {
        draft.sellPropertyDetail.data = action.sellPropertyDetail;
        draft.sellPropertyDetail.loading = false;
        draft.sellPropertyDetail.error = "";
        break;
      }
      case types.GET_SELL_PROPERTY_DETAIL.FAILED: {
        draft.sellPropertyDetail.loading = false;
        draft.sellPropertyDetail.error = action.error;
        break;
      }
      case types.GET_SELL_PROPERTY_DETAIL.RESET: {
        draft.sellPropertyDetail.data = {};
        break;
      }

      default:
        break;
    }
  });

export default propertiesReducer;
