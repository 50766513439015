import actionType from "utils/actionType";

// AUTHENTICATION TYPES
export const CHECK_AUTHENTICATION = actionType("CHECK_AUTHENTICATION");
export const GET_DATA_FROM_SOCIAL_PROVIDER = actionType(
  "GET_DATA_FROM_SOCIAL_PROVIDER"
);
export const GET_USER_DATA = actionType("GET_USER_DATA");
export const LOGIN = actionType("LOGIN");
export const SIGN_UP = actionType("SIGN_UP");
export const SIGN_OUT = actionType("SIGN_OUT");
export const UPDATE_MY_PROFILE = actionType("UPDATE_MY_PROFILE");
export const UPDATE_MY_PASSWORD = actionType("UPDATE_MY_PASSWORD");
export const GET_MY_PROPERTY = actionType("GET_MY_PROPERTY");
export const FORGOT_PASSWORD = actionType("FORGOT_PASSWORD");
export const RESET_PASSWORD = actionType("RESET_PASSWORD");
export const VERIFIED_RESET_TOKEN = actionType("VERIFIED_RESET_TOKEN");
export const GET_MY_REQUEST_PROPERTY = actionType("GET_MY_REQUEST_PROPERTY");

// PROPERTY TYPE
export const GET_PUBLISHED_PROPERTY_LIST = actionType(
  "GET_PUBLISHED_PROPERTY_LIST"
);
export const GET_PROPERTY_DETAIL = actionType("GET_PROPERTY_DETAIL");
export const CREATE_PROPERTY = actionType("CREATE_PROPERTY");
export const UPDATE_PROPERTY = actionType("UPDATE_PROPERTY");
export const GET_PROPERTY_AMENITIES = actionType("GET_PROPERTY_AMENITIES");
export const GET_PROPERTY_HISTORICAL = actionType("GET_PROPERTY_HISTORICAL");
export const CREATE_PUBLISH_PROPERTY = actionType("CREATE_PUBLISH_PROPERTY");
export const SEND_MESSAGE_TO_SELLER = actionType("SEND_MESSAGE_TO_SELLER");
export const GET_PROPERTY_IMAGES_LANDING_PAGE = actionType(
  "GET_PROPERTY_IMAGES_LANDING_PAGE"
);
export const GET_SELL_PROPERTY_DETAIL = actionType("GET_SELL_PROPERTY_DETAIL");

// ADMIN_TYPE
export const GET_ALL_USERS = actionType("GET_ALL_USERS");
export const GET_ALL_REQUEST_PROPERTIES = actionType(
  "GET_ALL_REQUEST_PROPERTIES"
);
export const GET_ALL_PUBLISHED_PROPERTIES = actionType(
  "GET_ALL_PUBLISHED_PROPERTIES"
);
export const GET_ALL_PROPERTIES = actionType("GET_ALL_PROPERTIES");
export const UPDATE_USER_STATUS = actionType("UPDATE_USER_STATUS");
export const UPDATE_PROPERTY_STATUS = actionType("UPDATE_PROPERTY_STATUS");
export const UPLOAD_PROPERTY_LANDING_PAGE = actionType(
  "UPLOAD_PROPERTY_LANDING_PAGE"
);
export const GET_ADMIN_DASHBOARD_INFO = actionType("GET_ADMIN_DASHBOARD_INFO");
