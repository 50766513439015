import HTTPMethod from "./index";

class UtilsService {
  // [POST]
  uploadFile = ({ base64EncodedFile }) =>
    HTTPMethod.post("/api/upload", {
      data: base64EncodedFile,
    });
  uploadMultipleFile = ({ listBase64EncodedFile }) =>
    HTTPMethod.post("/api/upload/multiple", {
      data: listBase64EncodedFile,
    });
  deleteFile = ({ fileId }) =>
    HTTPMethod.post("/api/upload/delete", {
      fileId,
    });
}

export default new UtilsService();
