import {
  chakra,
  Flex as CkFlex,
  Button as CkButton,
  Text as CkText,
} from "@chakra-ui/react";

import ButtonField from "components/ButtonField";

import chakraShouldForwardProp from "utils/chakraShouldForwardProp";

export const SignUpPopupContainer = chakra(CkFlex, {
  baseStyle: () => ({
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "40px",
  }),
});

export const ButtonSocial = chakra(CkButton, {
  baseStyle: () => ({
    width: "100%",
    background: "transparent",
    border: "solid 1px",
    borderColor: "background.third",
    marginBottom: "16px",
    fontWeight: "normal",
  }),
});

export const DividerContainer = chakra(CkFlex, {
  baseStyle: () => ({
    alignItems: "center",
    marginTop: "8px",
  }),
});

export const DividerText = chakra(CkText, {
  baseStyle: () => ({
    fontWeight: 700,
    color: "text.third",
    fontSize: "14px",
    mx: "16px",
  }),
});

export const SignUpButton = chakra(ButtonField, {
  baseStyle: () => ({
    width: "100%",
    marginBottom: "24px",
    marginTop: "24px",
    fontFamily: "Inter, sans-serif",
  }),
});

export const Text = chakra(CkText, {
  baseStyle: () => ({
    display: "inline-block",
    fontWeight: 700,
    fontSize: "16px",
    color: "text.primary",
    pointer: "cursor",
  }),
});

export const SignUpPopUpPrivacyContainer = chakra(CkFlex, {
  baseStyle: () => ({
    marginTop: "16px",
    alignItems: "flex-start",
  }),
});

export const SignUpPopUpPrivacyText = chakraShouldForwardProp(
  CkText,
  ({ privacyMode }) => ({
    display: "inline-block",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: privacyMode ? "bold" : "normal",
    color: privacyMode ? "text.primary" : "text.third",
  })
);
