import * as Yup from "yup";

export const formControlStyle = {
  marginBottom: "16px",
};

export const loginValidation = Yup.object({
  email: Yup.string().email().required("Email is a required field"),
  password: Yup.string().required("Password is a required field"),
});

export const signUpValidation = Yup.object({
  username: Yup.string().required("Your name is a required field"),
  email: Yup.string().email().required("Email is a required field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password must be less than 32 characters")
    .matches(/[a-z]+/, "Password need one character")
    .matches(/\d+/, "Password need one number")
    .matches(/[@$!%*#?&]+/, "Password need one special character")
    .required("Password is a required field"),
  privacyChecked: Yup.boolean().oneOf([true]),
});
