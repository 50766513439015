import { Form, Formik } from "formik";
import React, { useState } from "react";

import { useDispatch } from "react-redux";
import AuthsAction from "stores/redux/actions/AuthsAction";

import useNotification from "hooks/useNotification";

import { ResetPasswordPopupContainer } from "./resetPasswordPopup.styles";
import ButtonField from "components/ButtonField";
import InputField from "components/InputField";
import NormalText from "components/NormalText";
import TitleText from "components/TitleText";

const ResetPasswordPopup = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { forgotPassword } = AuthsAction;

  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const { openNotificationSuccess } = useNotification();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(values) => {
        setLoadingResetPassword(true);

        const handleCallbackResetPassword = () => {
          openNotificationSuccess(
            `We have sent a link to “${values?.email}” to reset your password please check your email.`
          );
          setLoadingResetPassword(false);
          closeModal();
        };

        dispatch(forgotPassword(values?.email, handleCallbackResetPassword));
      }}>
      {({ values, handleChange }) => (
        <Form>
          <TitleText
            position="absolute"
            top={18}
            title="Reset Password"
            width="max-content"
          />
          <ResetPasswordPopupContainer>
            <NormalText text="Please enter your e-mail address in the form below and we will send you a new password." />
            <InputField
              type="email"
              name="email"
              placeholder="Email Address"
              value={values.email}
              onChange={handleChange}
              formControlStyle={{
                my: "16px",
              }}
            />
            <ButtonField
              type="submit"
              buttonText="Send Message"
              w="full"
              isDisabled={!values?.email}
              loading={loadingResetPassword}
            />
          </ResetPasswordPopupContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordPopup;
