import React from "react";
import { RiAdminFill } from "react-icons/ri";
import { Icon as CkIcon } from "@chakra-ui/react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AuthsAction from "stores/redux/actions/AuthsAction";

import accountMenuList from "./constants";
import history from "utils/history";
import * as routes from "constants/routes";

import signOutIcon from "assets/icons/sign_out.svg";

import {
  AccountMenuDesktopContainer,
  ListItem,
} from "./accountMenuDesktop.styles";
import Icon from "components/Icon";
import NormalText from "components/NormalText";

const AccountMenuDesktop = ({ setShowAccountMenu }) => {
  const userData = useSelector(
    (state) => state.authsReducer.userData,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { signOut } = AuthsAction;

  return (
    <AccountMenuDesktopContainer>
      {userData?.role === "admin" && (
        <ListItem
          onClick={() => {
            setShowAccountMenu(false);
            history.push(routes.ADMIN_DASHBOARD);
          }}
          alignItems="center">
          <CkIcon as={RiAdminFill} w="13px" height="13px" />
          <NormalText text="Admin Dashboard" ml="10px" />
        </ListItem>
      )}
      {accountMenuList.map((accountMenu, index) => (
        <ListItem
          key={index}
          onClick={() => {
            setShowAccountMenu(false);
            history.push(accountMenu.path);
          }}>
          <Icon icon={accountMenu.icon} size={13} />
          <NormalText text={accountMenu.label} ml="10px" />
        </ListItem>
      ))}
      <ListItem onClick={() => dispatch(signOut())}>
        <Icon icon={signOutIcon} size={13} />
        <NormalText text="Log Out" ml="10px" />
      </ListItem>
    </AccountMenuDesktopContainer>
  );
};

export default AccountMenuDesktop;
