import { takeLatest, put, call } from "redux-saga/effects";

import AdminsService from "services/AdminsService";

import * as types from "constants/types";

import AdminsAction from "stores/redux/actions/AdminsAction";

function* getAllUsers({ page, filterObject }) {
  try {
    const { data } = yield call(AdminsService.getAllUsers, {
      page,
      filterObject,
    });

    yield put(AdminsAction.getAllUserSucceeded(data.results, data.total));
  } catch (error) {
    yield put(AdminsAction.getAllUserFailed(error?.response?.data?.msg));
    console.log(error);
  }
}

function* getAllRequestProperties({ page, filterObject }) {
  try {
    const { data } = yield call(AdminsService.getAllRequestProperties, {
      page,
      filterObject,
    });

    yield put(
      AdminsAction.getAllRequestPropertiesSucceeded(data.results, data.total)
    );
  } catch (error) {
    yield put(
      AdminsAction.getAllRequestPropertiesFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* getAllPublishedProperties({ page }) {
  try {
    const { data } = yield call(AdminsService.getAllPublishedProperties, {
      page,
    });

    yield put(
      AdminsAction.getAllPublishedPropertiesSucceeded(data.results, data.total)
    );
  } catch (error) {
    yield put(
      AdminsAction.getAllPublishedPropertiesFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* getAllProperties({ page, filterObject }) {
  try {
    const { data } = yield call(AdminsService.getAllProperties, {
      page,
      filterObject,
    });
  

    yield put(AdminsAction.getAllPropertiesSucceeded(data.results, data.total, page));
  } catch (error) {
    yield put(AdminsAction.getAllPropertiesFailed(error?.response?.data?.msg));
    console.log(error);
  }
}

function* updateUserStatus({ userId, status, cb }) {
  try {
    yield call(AdminsService.updateUserStatus, {
      userId,
      status,
    });
    yield cb?.();
  } catch (error) {
    console.log(error);
  }
}

function* updatePropertyStatus({ propertyId, status, page, cb }) {
  try {
    yield call(AdminsService.updatePropertyStatus, {
      propertyId,
      status,
    });
    yield cb?.();
    yield call(getAllPublishedProperties, {
      page: 1,
    });
    yield call(getAllRequestProperties, {
      page,
    });
    yield call(getAdminDashboardInfo);
  } catch (error) {
    console.log(error);
  }
}

function* uploadPropertyImagesLandingPage({ propertyImagesLandingPage, cb }) {
  try {
    yield call(AdminsService.uploadPropertyImagesLandingPage, {
      images: propertyImagesLandingPage,
    });
    yield cb?.();
  } catch (error) {
    console.log(error);
  }
}

function* getAdminDashboardInfo() {
  try {
    const { data } = yield call(AdminsService.getAdminDashboardInfo);

    yield put(AdminsAction.getAdminDashboardInfoSucceeded(data));
  } catch (error) {
    yield put(
      AdminsAction.getAdminDashboardInfoFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

export default function* adminsSaga() {
  yield takeLatest(types.GET_ALL_USERS.REQUEST, getAllUsers);
  yield takeLatest(
    types.GET_ALL_PUBLISHED_PROPERTIES.REQUEST,
    getAllPublishedProperties
  );
  yield takeLatest(
    types.GET_ALL_REQUEST_PROPERTIES.REQUEST,
    getAllRequestProperties
  );
  yield takeLatest(types.GET_ALL_PROPERTIES.REQUEST, getAllProperties);
  yield takeLatest(types.UPDATE_USER_STATUS.ROOT, updateUserStatus);
  yield takeLatest(types.UPDATE_PROPERTY_STATUS.ROOT, updatePropertyStatus);
  yield takeLatest(
    types.UPLOAD_PROPERTY_LANDING_PAGE.ROOT,
    uploadPropertyImagesLandingPage
  );
  yield takeLatest(
    types.GET_ADMIN_DASHBOARD_INFO.REQUEST,
    getAdminDashboardInfo
  );
}
