import produce from "immer";

import * as types from "constants/types";

const initialState = {
  userData: {},
  token: JSON.parse(localStorage.getItem("token")),
  loading: false,
  checkAuthentication: localStorage.getItem("token") === "true" ? true : false,
  checkResetPasswordStatus: JSON.parse(localStorage.getItem("isResetPassword")),
  error: "",
  modeAuthCallback: false,
  myPropertyList: {
    loading: false,
    data: [],
    total: 0,
    error: "",
  },
  myRequestPropertyList: {
    loading: false,
    data: [],
    error: "",
  },
  resetPassword: {
    loading: false,
    error: "",
  },
};

const authsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CHECK_AUTHENTICATION.SUCCEEDED: {
        draft.checkAuthentication = true;
        break;
      }

      case types.GET_DATA_FROM_SOCIAL_PROVIDER.REQUEST: {
        draft.modeAuthCallback = true;
        draft.loading = true;
        break;
      }
      case types.GET_DATA_FROM_SOCIAL_PROVIDER.SUCCEEDED: {
        draft.modeAuthCallback = false;
        draft.userData = action.userData;
        draft.token = action.token;
        draft.loading = false;
        draft.error = "";
        draft.checkAuthentication = true;
        break;
      }
      case types.GET_DATA_FROM_SOCIAL_PROVIDER.FAILED: {
        draft.modeAuthCallback = false;
        draft.userData = {};
        draft.token = "";
        draft.loading = false;
        draft.error = action.error;
        draft.checkAuthentication = false;
        break;
      }

      case types.LOGIN.REQUEST:
      case types.SIGN_UP.REQUEST: {
        draft.loading = true;
        break;
      }

      case types.LOGIN.SUCCEEDED:
      case types.SIGN_UP.SUCCEEDED: {
        draft.userData = action.userData;
        draft.token = action.token;
        draft.loading = false;
        draft.error = "";
        draft.checkAuthentication = true;
        break;
      }

      case types.GET_USER_DATA.SUCCEEDED: {
        draft.userData = action.userData;
        break;
      }

      case types.LOGIN.FAILED:
      case types.SIGN_UP.FAILED:
      case types.GET_USER_DATA.FAILED:
      case types.CHECK_AUTHENTICATION.FAILED: {
        draft.userData = {};
        draft.token = "";
        draft.loading = false;
        draft.error = action.error;
        draft.checkAuthentication = false;
        draft.checkResetPasswordStatus = false;
        draft.modeAuthCallback = false;
        draft.myPropertyList = {
          loading: false,
          data: [],
          total: 0,
          error: "",
        };
        draft.resetPassword = {
          loading: false,
          error: "",
        };
        break;
      }

      case types.UPDATE_MY_PROFILE.REQUEST: {
        draft.loading = true;
        break;
      }
      case types.UPDATE_MY_PROFILE.SUCCEEDED: {
        draft.userData = action.userData;
        draft.loading = false;
        draft.error = "";
        break;
      }
      case types.UPDATE_MY_PROFILE.FAILED: {
        draft.error = action.error;
        draft.loading = false;
        break;
      }

      case types.UPDATE_MY_PASSWORD.REQUEST: {
        draft.loading = true;
        break;
      }
      case types.UPDATE_MY_PASSWORD.SUCCEEDED: {
        draft.loading = false;
        draft.error = "";
        break;
      }
      case types.UPDATE_MY_PASSWORD.FAILED: {
        draft.loading = false;
        draft.error = action.error;
        break;
      }

      case types.GET_MY_PROPERTY.REQUEST: {
        draft.myPropertyList.loading = true;
        break;
      }
      case types.GET_MY_PROPERTY.SUCCEEDED: {
        draft.myPropertyList.data = action.myPropertyList;
        draft.myPropertyList.total = action.total;
        draft.myPropertyList.loading = false;
        draft.myPropertyList.error = "";
        break;
      }
      case types.GET_MY_PROPERTY.FAILED: {
        draft.myPropertyList.loading = false;
        draft.myPropertyList.error = action.error;
        break;
      }

      case types.UPDATE_PROPERTY_STATUS.SUCCEEDED: {
        draft.myPropertyList.data.map((item) =>
          item.id === action.propertyId ? action.property : item
        );
        draft.myPropertyList.error = "";
        break;
      }
      case types.UPDATE_PROPERTY_STATUS.FAILED: {
        draft.myPropertyList.error = action.error;
        break;
      }

      case types.VERIFIED_RESET_TOKEN.ROOT: {
        draft.checkResetPasswordStatus = true;
        break;
      }

      case types.RESET_PASSWORD.REQUEST: {
        draft.resetPassword.loading = true;
        break;
      }
      case types.RESET_PASSWORD.SUCCEEDED: {
        draft.resetPassword.loading = false;
        draft.checkResetPasswordStatus = false;
        draft.resetPassword.error = "";
        break;
      }
      case types.RESET_PASSWORD.FAILED: {
        draft.resetPassword.loading = false;
        draft.resetPassword.error = action.error;
        draft.checkResetPasswordStatus = false;
        break;
      }

      case types.GET_MY_REQUEST_PROPERTY.REQUEST: {
        draft.myRequestPropertyList.loading = true;
        break;
      }
      case types.GET_MY_REQUEST_PROPERTY.SUCCEEDED: {
        draft.myRequestPropertyList.data = action.myRequestPropertyList;
        draft.myRequestPropertyList.loading = false;
        break;
      }
      case types.GET_MY_REQUEST_PROPERTY.FAILED: {
        draft.myRequestPropertyList.loading = true;
        draft.myRequestPropertyList.error = action.error;
        break;
      }

      default:
        break;
    }
  });

export default authsReducer;
