import HTTPMethod from "./index";

class AuthsService {
  // [GET]
  getAllUsers = ({ page, filterObject }) =>
    HTTPMethod.get(
      `/admin/all-user?page=${page}${
        filterObject?.sortBy ? `&sortBy=${filterObject?.sortBy}` : ""
      }${filterObject?.keyword ? `&keyword=${filterObject?.keyword}` : ""}`
    );
  getAllRequestProperties = ({ page, filterObject }) =>
    HTTPMethod.get(
      `/admin/all-request-properties?page=${page}${
        filterObject?.sortBy ? `&sortBy=${filterObject?.sortBy}` : ""
      }${filterObject?.keyword ? `&keyword=${filterObject?.keyword}` : ""}`
    );
  getAllPublishedProperties = ({ page }) =>
    HTTPMethod.get(`/admin/all-publish-properties?page=${page}&limit=100`);
  getAllProperties = ({ page, filterObject }) =>
    HTTPMethod.get(
      `/admin/all-properties?page=${page}${
        filterObject?.sortBy ? `&sortBy=${filterObject?.sortBy}` : ""
      }${filterObject?.keyword ? `&keyword=${filterObject?.keyword}` : ""}`
    );
  getAdminDashboardInfo = () => HTTPMethod.get("/admin/dashboard-info");

  // [POST]
  uploadPropertyImagesLandingPage = (data) =>
    HTTPMethod.post("/admin/upload/property-landing-page", data);

  // [PUT]
  updateUserStatus = ({ userId, status }) =>
    HTTPMethod.put(`/admin/update-status/user/${userId}`, { status });
  updatePropertyStatus = ({ propertyId, status }) =>
    HTTPMethod.put(`/admin/update-status/publish-properties/${propertyId}`, {
      status,
    });
}

export default new AuthsService();
