import * as routes from "constants/routes";

import accountIcon from "assets/icons/account.svg";
import propertyIcon from "assets/icons/property.svg";
// import activityIcon from "assets/icons/activity.svg";

const accountMenuList = [
  {
    path: routes.MY_ACCOUNT,
    label: "My Account",
    icon: accountIcon,
  },
  {
    path: routes.MY_PROPERTY,
    label: "My Property",
    icon: propertyIcon,
  },
  // {
  // 	path: routes.MY_ACTIVITY,
  // 	label: "My Activity",
  // 	icon: activityIcon,
  // },
];

export default accountMenuList;
