// ROUTES COMMON
export const HOMEPAGE = "/";
export const LANDING_PAGE = "/landing-page";
export const MY_ACCOUNT = "/my-account";
export const MY_ACTIVITY = "/my-activity";
export const ABOUT_US = "/about-us";
export const NEWS = "/property-news";

// ROUTES PROPERTY
export const BUY_PROPERTY = "/buy-property";
export const PROPERTY_SEARCH = "/search";
export const PROPERTY_DETAIL = "/property";
export const MY_PROPERTY = "/my-property";
export const SELL_PROPERTY = "/sell-property";
export const EDIT_PROPERTY = "/edit-property";
export const VIEW_ACTIVITY = "/property/view-activity";
export const CONFIRM_PROPERTY = "/confirm-property";
export const PUBLISH_PROPERTY = "/publish-property";

// ROUTES CALLBACK
export const CALLBACK_MY_INFO = "/callback";
export const CALLBACK_FACEBOOK = "/auth/facebook/callback";
export const CALLBACK_GOOGLE = "/auth/google/callback";
export const CALLBACK_RESET_PASSWORD = "/auth/reset-password/callback";

// ROUTES ADMIN
export const ADMIN_DASHBOARD = "/admin-dashboard";
