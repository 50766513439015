import React from "react";
import { RiAdminFill } from "react-icons/ri";
import { Icon as CkIcon } from "@chakra-ui/react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AuthsAction from "stores/redux/actions/AuthsAction";

import { accountMenuList } from "./constants";
import history from "utils/history";
import * as routes from "constants/routes";

import signOutIcon from "assets/icons/sign_out.svg";

import {
  AccountMenuMobileContainer,
  ListItem,
  ListItemText,
} from "./accountMenuMobile.styles";
import Icon from "components/Icon";

const AccountMenuMobile = ({ mobileNav }) => {
  const userData = useSelector(
    (state) => state.authsReducer.userData,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { signOut } = AuthsAction;

  const handleTabMenu = (path) => {
    mobileNav?.onClose();
    history.push(path);
  };

  return (
    <AccountMenuMobileContainer>
      {userData?.role === "admin" && (
        <ListItem
          onClick={() => handleTabMenu(routes.ADMIN_DASHBOARD)}
          alignItems="center">
          <CkIcon as={RiAdminFill} w="13px" height="13px" />
          <ListItemText>Admin Dashboard</ListItemText>
        </ListItem>
      )}
      {accountMenuList.map((accountMenu, index) => (
        <ListItem key={index} onClick={() => handleTabMenu(accountMenu.path)}>
          <Icon icon={accountMenu.icon} size={13} />
          <ListItemText>{accountMenu.label}</ListItemText>
        </ListItem>
      ))}
      <ListItem
        onClick={() => {
          dispatch(signOut());
          mobileNav?.onClose();
        }}>
        <Icon icon={signOutIcon} size={13} />
        <ListItemText>Log Out</ListItemText>
      </ListItem>
    </AccountMenuMobileContainer>
  );
};

export default AccountMenuMobile;
