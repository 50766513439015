import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react";
import { GrClose } from "react-icons/gr";

import TitleText from "components/TitleText";

const useModal = ({
  usingFooter = true,
  usingHeader = false,
  modalBody = null,
  handleSave = () => {},
  handleClose = () => {},
  positionTitle = "left",
  sizeModal = "md",
  isCentered = false,
}) => {
  const ModalBodyComponent = modalBody;

  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    data: null,
    redirectTo: "",
  });

  const [checkMobileView] = useMediaQuery("(max-width: 48em)");

  const open = useCallback(
    ({ title = "", data, redirectTo = "" }) => {
      setModalState((prevState) => ({
        ...prevState,
        isOpen: true,
        title,
        redirectTo,
        data,
      }));
      handleSave?.();
    },
    [handleSave]
  );

  const close = useCallback(() => {
    setModalState({
      isOpen: false,
      title: "",
      data: null,
      redirectTo: "",
    });
    handleClose?.();
  }, [handleClose]);

  const Dialog = useCallback(
    (closeModal) => (
      <Modal isCentered={isCentered} size={sizeModal} isOpen={modalState.isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent mx={{base: "0", xl: "2"}}>
          {usingHeader && (
            <TitleText
              title={modalState.title}
              textAlign={positionTitle}
              width="max-content"
              marginLeft="20px"
              marginTop="20px"
            />
          )}
          {checkMobileView ? (
            <Icon
              as={GrClose}
              fontSize="18px"
              position="absolute"
              top="20px"
              right="25px"
              onClick={close}
            />
          ) : (
            <ModalCloseButton cursor="pointer" fontSize="18px" top="20px" />
          )}

          <ModalBody py={8}>
            <ModalBodyComponent closeModal={closeModal} {...modalState} />
          </ModalBody>
          {usingFooter && (
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={close}>
                Close
              </Button>
              <Button variant="ghost" onClick={open}>
                Save
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    ),
    [
      sizeModal,
      modalState,
      close,
      usingHeader,
      positionTitle,
      checkMobileView,
      usingFooter,
      open,
      isCentered,
    ]
  );

  return {
    open,
    close,
    Dialog,
  };
};

export default useModal;
