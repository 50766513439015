import { takeLatest, put, call } from "redux-saga/effects";
import decoded from "jwt-decode";

import AuthsService from "services/AuthsService";
import UtilsService from "services/UtilsService";

import UseLocalStorage from "hooks/useLocalStorage";
import * as types from "constants/types";
import history from "utils/history";

import AuthsAction from "stores/redux/actions/AuthsAction";

function* getUserData() {
  try {
    const { data } = yield call(AuthsService.getUserData);

    yield put(AuthsAction.getUserDataSucceeded({ userData: data }));
  } catch (error) {
    console.log(error);
    yield put(AuthsAction.getUserDataFailed(error?.response?.data?.message));
    return error?.response?.status;
  }
}

function* getDataFromSocialProvider({ socialProvider, token }) {
  const { saveToLocal } = UseLocalStorage();

  try {
    const { data } = yield call(AuthsService.getUserDataFromSocialProvider, {
      socialProvider,
      token,
    });

    yield put(
      AuthsAction.getDataFromSocialProviderSucceeded({
        token: data.token,
        userData: data,
      })
    );
    yield saveToLocal("token", data.token);
    yield history.push("/");
  } catch (error) {
    yield put(
      AuthsAction.getDataFromSocialProviderFailed(error?.response?.data?.msg)
    );
    console.log(error);
  }
}

function* login({ email, password, cb, cbError }) {
  const { saveToLocal } = UseLocalStorage();

  try {
    const { data } = yield call(AuthsService.login, { email, password });

    yield saveToLocal("token", data.token);
    yield put(
      AuthsAction.loginSucceeded({
        token: data.token,
        userData: data,
      })
    );
    yield cb?.();
  } catch (error) {
    yield put(AuthsAction.loginFailed(error?.response?.data?.msg));
    yield cbError?.(error?.response?.data?.msg);
    console.log(error);
  }
}

function* signUp({ username, email, password, cb, cbError }) {
  const { saveToLocal } = UseLocalStorage();

  try {
    const { data } = yield call(AuthsService.signUp, {
      username,
      email,
      password,
    });

    yield saveToLocal("token", data.token);
    yield put(
      AuthsAction.signUpSucceeded({
        token: data.token,
        userData: data,
      })
    );
    yield cb?.();
  } catch (error) {
    yield put(AuthsAction.signUpFailed(error?.response?.data?.msg));
    yield cbError?.(error?.response?.data?.msg);
    console.log(error);
  }
}

function* signOut() {
  const { removeFromLocal } = UseLocalStorage();

  yield removeFromLocal("querySearch");
  yield removeFromLocal("token");
  yield removeFromLocal("isResetPassword");
  yield removeFromLocal("resetToken");
  yield put(AuthsAction.checkAuthenticationFailed());
}

function* updateMyProfile({
  username,
  email,
  phoneNumber,
  countryCode,
  profileImage,
  cb,
}) {
  try {
    const finalUserProfile = { username, phoneNumber, email, countryCode };

    if (profileImage) {
      const {
        data: { file },
      } = yield call(UtilsService.uploadFile, {
        base64EncodedFile: profileImage,
      });

      finalUserProfile.profileImage = file?.url;
    }

    const { data } = yield call(AuthsService.updateMyProfile, {
      updateData: finalUserProfile,
    });

    yield put(AuthsAction.updateMyProfileSucceeded(data));
    yield cb?.();
  } catch (error) {
    yield put(AuthsAction.updateMyProfileFailed(error?.response?.data?.msg));
    console.log(error);
  }
}

function* updateMyPassword({ oldPassword, newPassword, cb, cbError }) {
  try {
    yield call(AuthsService.updateMyPassword, {
      updatePassword: {
        oldPassword,
        newPassword,
      },
    });
    yield put(AuthsAction.updateMyPasswordSucceeded());
    yield cb?.();
  } catch (error) {
    console.log(error);
    yield put(AuthsAction.updateMyPasswordFailed(error?.response?.data?.msg));
    yield cbError?.(error?.response?.data?.msg);
  }
}

function* getMyProperty({ page, typeStatus, sortBy }) {
  try {
    const { data } = yield call(AuthsService.getMyProperty, {
      page,
      typeStatus,
      sortBy,
    });

    yield put(AuthsAction.getMyPropertySucceeded(data.results, data.total));
  } catch (error) {
    yield put(AuthsAction.getMyPropertyFailed(error?.response?.data?.msg));
    console.log(error);
  }
}

function* forgotPassword({ email, cb }) {
  try {
    yield call(AuthsService.forgotPassword, { email });
    yield cb?.();
  } catch (error) {
    console.log(error);
  }
}

function* resetPassword({ token, newPassword, cb, cbError }) {
  const { removeFromLocal } = UseLocalStorage();

  try {
    const { data } = yield call(AuthsService.resetPassword, {
      token,
      newPassword,
    });

    yield removeFromLocal("isResetPassword");
    yield removeFromLocal("resetToken");
    yield cb?.(data?.msg);
    yield put(AuthsAction.resetPasswordSucceeded());
    yield history.push("/");
  } catch (error) {
    console.log(error);
    yield cbError?.(error?.response?.data?.msg);
  }
}

function* verifiedResetToken({ resetToken }) {
  const { saveToLocal, removeFromLocal } = UseLocalStorage();

  try {
    yield call(AuthsService.verifiedResetToken, { resetToken });
    saveToLocal("isResetPassword", true);
    saveToLocal("resetToken", resetToken);
  } catch (error) {
    console.log(error);
    yield history.push("/");
    yield removeFromLocal("isResetPassword");
    yield removeFromLocal("resetToken");
    yield call(signOut);
  }
}

function* getMyRequestProperty() {
  try {
    const { data } = yield call(AuthsService.getMyRequestProperty);

    yield put(AuthsAction.getMyRequestPropertySucceeded(data));
  } catch (error) {
    console.log(error);
    yield put(
      AuthsAction.getMyRequestPropertyFailed(error?.response?.data?.msg)
    );
  }
}

function* checkAuthentication() {
  const { getFromLocal } = UseLocalStorage();

  try {
    const token = yield getFromLocal("token");
    const statusCode = yield call(getUserData);

    if (statusCode === 401 || !token) {
      yield call(signOut);
    } else {
      const decodedToken = decoded(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) {
        yield call(signOut);
      } else {
        yield put(AuthsAction.checkAuthenticationSucceeded());
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* authsSaga() {
  yield takeLatest(types.CHECK_AUTHENTICATION.REQUEST, checkAuthentication);
  yield takeLatest(
    types.GET_DATA_FROM_SOCIAL_PROVIDER.REQUEST,
    getDataFromSocialProvider
  );
  yield takeLatest(types.LOGIN.REQUEST, login);
  yield takeLatest(types.SIGN_UP.REQUEST, signUp);
  yield takeLatest(types.SIGN_OUT.ROOT, signOut);
  yield takeLatest(types.GET_USER_DATA.REQUEST, getUserData);
  yield takeLatest(types.UPDATE_MY_PROFILE.REQUEST, updateMyProfile);
  yield takeLatest(types.UPDATE_MY_PASSWORD.REQUEST, updateMyPassword);
  yield takeLatest(types.GET_MY_PROPERTY.REQUEST, getMyProperty);
  yield takeLatest(types.FORGOT_PASSWORD.ROOT, forgotPassword);
  yield takeLatest(types.RESET_PASSWORD.REQUEST, resetPassword);
  yield takeLatest(types.VERIFIED_RESET_TOKEN.ROOT, verifiedResetToken);
  yield takeLatest(types.GET_MY_REQUEST_PROPERTY.REQUEST, getMyRequestProperty);
}
