// BACKGROUND COLOR
export const PRIMARY_BACKGROUND = "#BCE954";
export const SECONDARY_BACKGROUND = "#01042A";
export const FOURTH_BACKGROUND = "#18524C";
export const FIFTH_BACKGROUND = "#91C51A";
export const SIXTH_BACKGROUND = "#EDFFC5";
export const GREY_500_BACKGROUND = "#CCD1D7";
export const GREY_250_BACKGROUND = "#E5E8EB";
export const GREY_100_BACKGROUND = "#F5F6F7";
export const ERROR_BACKGROUND = "#FFD9D9";

// TEXT COLOR
export const PRIMARY_TEXT = "#91C51A";
export const SECONDARY_TEXT = "#01042A";
export const THIRD_TEXT = "#99A4AE";
export const FOURTH_TEXT = "#18524C";
export const FIFTH_TEXT = "#102340";
export const ERROR_TEXT = "#E53E3E";
