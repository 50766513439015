import { chakra, Flex as CkFlex } from "@chakra-ui/react";

export const CopyRightContainer = chakra(CkFlex, {
  baseStyle: () => ({
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",
    background: "background.grey.100",
    height: "56px",
  }),
});
