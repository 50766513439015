export const socialLinkList = [
  {
    link: "https://www.facebook.com/Property-Genie-106676488486360",
    label: "Facebook",
  },
  {
    link: "https://www.instagram.com/propertygenie.io/",
    label: "Instagram",
  },
  {
    link: "https://twitter.com/PropertyGenieio",
    label: "Twitter",
  },
  {
    link: "https://www.tiktok.com/@propertygenie.io",
    label: "TikTok",
  },
];
