import {
  chakra,
  Box as CkBox,
  Center as CkCenter,
  HStack as CkHStack,
} from "@chakra-ui/react";

export const HeaderContainer = chakra(CkBox, {
  baseStyle: () => ({
    position: "fixed",
    top: 0,
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: {
      base: "81px",
      md: "120px"
    },
    zIndex: 1000,
    boxShadow: {
      base: "none",
      md: "0px 4px 8px rgba(16, 35, 64, 0.2)",
    },
    background: "white",
  }),
});

export const Center = chakra(CkCenter, {
  baseStyle: () => ({
    marginEnd: "10",
    cursor: "pointer",
  }),
});

export const AuthButtonContainer = chakra(CkHStack, {
  baseStyle: () => ({
    minW: "240px",
    justifyContent: "flex-start",
  }),
});
