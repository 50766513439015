import {
  chakra,
  Box as CkBox,
  Stack as CkStack,
  Flex as CkFlex,
} from "@chakra-ui/react";

export const FooterContainer = chakra(CkBox, {
  baseStyle: () => ({
    paddingTop: {
      base: "28px",
      md: "64px",
    },
    px: {
      base: 4,
      md: 6,
      lg: "100px",
    },
    boxShadow: "0px -1px 0px #E5E8EB",
  }),
});

export const FooterWrapper = chakra(CkStack, {
  baseStyle: () => ({
    direction: { base: "column", md: "row" },
    spacing: { base: "10", md: "20" },
  }),
});

export const FooterRightSection = chakra(CkFlex, {
  baseStyle: () => ({
    flexDir: { base: "column", xl: "row" },
    flex: 1,
  }),
});
